import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import { BorderLeft, SettingsCell } from '@material-ui/icons';
const styles = StyleSheet.create({
  page: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,

  },
  headerContainer: {
    marginTop: 10,
    textAlign: 'center',
    flexDirection: "row",
    fontSize: 14
  },
  reporttitle: {
    // width:100, 
    fontSize: 15,
    textAlign: 'center'
  },
  reporttitle1: {
    // width:100, 
    fontSize: 13,
    textAlign: 'center'
  },
  row1: {
    marginTop: 25,
    flexDirection: "row",
    marginLeft: 10,
  },
  row1Section1: {
    width: 300,
    fontSize: 12,
    textAlign: 'left'
  },
  row1Section2: {
    width: 100,
    fontSize: 12,
    textAlign: 'right'
  },
  row2: {
    marginTop: 10,
    flexDirection: "row",
    marginLeft: 10,
    fontSize: 12,
    textAlign: 'left'
  },
  row3: {
    marginTop: 40,
    flexDirection: "row",
    marginLeft: 10,
    fontSize: 12,
    textAlign: 'left'
  },
  table: {
    marginLeft: 10
  },
  tableHeader: {
    fontSize: 11,
    height: 20,
    textAlign: 'left',
    marginLeft: 10
  },
  tabletextRow: {
    fontSize: 10,
    height: 14,
    textAlign: 'left',
    marginLeft: 10
  },
});


export const PrintTPAChecklist = props => {

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [data1, setData1] = useState([{ sno: 1, description: "Claim No" },
  { sno: 2, description: "Patient Name" }, { sno: 3, description: "DOA" }, {
    sno: 4, description: "DOD"
  }, { sno: 5, description: "Total Bill Amount" }, { sno: 6, description: "Total Patient Paid Amount" }, 
  { sno: 7, description: "Discount Amount" },
  { sno: 8, description: "Medi Assist Final Authorised Amount" }]);
  const [data2, setData2] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [tpaId, setTpaId] = useState(0);
  const [tpaType, setTpaType] = useState("");
  const [footerDetail, setFooterDetail] = useState("");
  const [footerDetail1, setFooterDetail1] = useState("");
  const LoadData= () => {
    
    setLoading(true);
    let tpaid=props.tpaId;
    let id=props.realizOrdId;
        apiClient.realizationOrderPrintTPADeclaration (id,tpaid).then(res => {
          
          debugger 
          let _data=res.data[0];
          setData(_data);
          let section1=[];
          section1.push({ sno: 1, description: "Claim No",description2:_data.ClaimNo });
          section1.push({ sno: 2, description: "Patient Name",description2:_data.Pat_Name });
          section1.push({ sno: 3, description: "DOA" ,description2:_data.DOA});
          section1.push({ sno: 4, description: "DOD" ,description2:_data.DOD},);
          section1.push({ sno: 5, description: "Total Bill Amount" ,description2:_data.Claim_Amt});
          section1.push({ sno: 6, description: "Total Patient Paid Amount" ,description2:_data.Reclaim_Amt});
          section1.push({ sno: 7, description: "Discount Amount" ,description2:_data.Hosp_Disc});
          section1.push({ sno: 8, description: "Medi Assist Final Authorised Amount" ,description2:_data.Settle_Amt});
          setData1([...section1])
          setData2(JSON.parse(_data.CheckList));
          setLoading(false);

        }).catch(err => {
          setLoading(false);
          console.log(err);
        });

    }
  useEffect(() => {setTpaId(parseInt(props.tpaId.toString())); LoadData();},[]);
  const datevalue=()=>{ 
    const event = new Date();
    
    const options = { dateStyle: 'short' };
      return event.toLocaleString('en', options);
  }
  return (


    <React.Fragment>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
      </div>
      {(isLoading === false && tpaId===12 ) ?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
            <Page size="A4" style={styles.page}>
              <View>
                <View style={styles.reporttitle}>
                  <Text> Covering Letter</Text>
                </View>
                <View style={styles.reporttitle1}>
                  <Text> Please Ensure that, this letter to be filled,singed and uploaded in the MB+</Text>
                </View>
                <View style={styles.reporttitle1}>
                  <Text> along with claim documents for faster processing of claims</Text>
                </View>
                <View style={styles.row1}>
                  <View style={styles.row1Section1}>
                    <Text> To</Text>
                  </View>
                  <View style={styles.row1Section2}>
                    <Text> Date:-{datevalue()} </Text>
                  </View>
                </View>
                <View style={styles.row2}>
                  <Text> {data["TpaName"]},</Text>
                </View>
                <View style={styles.row2}>
                  <Text> Dear Sir,</Text>
                </View>
                <View style={styles.row2}>
                  <Text> We hereby Confirm that all details/documents uploaded in the MB+ are as per orignal claim documents which will be couriered/submitted to Medi Assist later.</Text>
                </View>
                <View style={styles.row2}>
                  <Text> We hereby confirm that we have mentioned TPA name and insurer Name(in Payable by column) in the Bill and no changes will be done later on the bill and details provided.</Text>
                </View>
                <View style={styles.row2}>
                  <Table data={data1} style={styles.table}>
                    <TableHeader>
                      <TableCell style={styles.tableHeader} weighting={0.2}> Sl.No </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Particulars </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Details </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell style={styles.tabletextRow} weighting={0.2} getContent={(r) => r.sno} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.description} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.description2} />
                    </TableBody>
                  </Table>
                </View>
                <View style={styles.row2}>
                  <Text>Check list for Cashless documents-- Please tick and confirm the attachments </Text>
                </View>
                <View style={styles.row2}>
                  <Table data={data2} style={styles.table}>
                    <TableHeader>
                      <TableCell style={styles.tableHeader} weighting={0.2}> Sl.No </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Dcouments Detail </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Tick to confirm attachment </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell style={styles.tabletextRow} weighting={0.2} getContent={(r) => r.SLNO} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.Details} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r["Details1"]} />
                    </TableBody>
                  </Table>
                </View>
                <View style={styles.row2}>
                  <Text> We take complete responsibillty in case of any differences in the documents uploaded in MB+ vs the original documents submitted later</Text>
                </View>
                <View style={styles.row2}>
                  <Text> Thanking YOU</Text>
                </View>
                <View style={styles.row3}>
                  <Text> Authorised Signatory and Seal</Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer> : null}


        {(isLoading === false  && tpaId===15 ) ?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
            <Page size="A4" style={styles.page}>
              <View>
                {/* <View style={styles.reporttitle}>
                  <Text> Covering Letter</Text>
                </View> */}
                <View style={styles.reporttitle1}>
                  <Text> Self declaration forsubmitting scanned copies of cashless documents by Hospitals</Text>
                </View>
                {/* <View style={styles.reporttitle1}>
                  <Text> along with claim documents for faster processing of claims</Text>
                </View> */}
                <View style={styles.row1}>
                  <View style={styles.row1Section1}>
                    <Text> To</Text>
                  </View>
                  <View style={styles.row1Section2}>
                    <Text> Date:-{datevalue()} </Text>
                  </View>
                </View>
                <View style={styles.row2}>
                  <Text> {data["TpaName"]},</Text>
                </View>
                <View style={styles.row2}>
                  <Text> Dear Sir,</Text>
                </View>
                <View style={styles.row2}>
                  <Text> We hereby Confirm that all details/documents uploaded in the PHS portal are as per orignal claim documents which will be couriered/submitted to PHS later.</Text>
                </View>
                <View style={styles.row2}>
                  <Text> We hereby confirm that we have mentioned TPA name and insurer Name(in Payable by column) in the Bill and no changes will be done later on the bill and details provided.</Text>
                </View>
                <View style={styles.row2}>
                  <Table data={data1} style={styles.table}>
                    <TableHeader>
                      <TableCell style={styles.tableHeader} weighting={0.2}> Sl.No </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Particulars </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Details </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell style={styles.tabletextRow} weighting={0.2} getContent={(r) => r.sno} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.description} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.description2} />
                    </TableBody>
                  </Table>
                </View>
                <View style={styles.row2}>
                  <Text>Check list for Cashless documents-- Please tick and confirm the attachments </Text>
                </View>
                <View style={styles.row2}>
                  <Table data={data2} style={styles.table}>
                    <TableHeader>
                      <TableCell style={styles.tableHeader} weighting={0.2}> Sl.No </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Dcouments Detail </TableCell>
                      <TableCell style={styles.tableHeader} weighting={1} > Tick to confirm attachment </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell style={styles.tabletextRow} weighting={0.2} getContent={(r) => r.SLNO} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.Details} />
                      <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r["Details1"]} />
                    </TableBody>
                  </Table>
                </View>
                <View style={styles.row2}>
                  <Text> We take complete responsibility of the discrepancy in documents (if found any) which are uploaded in PHS portal Vs the original documents which will be submitted later to Paramount TPA.</Text>
                </View>
                <View style={styles.row2}>
                  <Text> Thanking YOU</Text>
                </View>
                <View style={styles.row3}>
                  <Text> Authorised Signatory and Seal</Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer> : null}


    </React.Fragment>
  );
}

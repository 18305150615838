import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const UsersCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="User Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input}/>
      <TextInput source="userName" formClassName={classes.two_three_input}  />
      <PasswordInput source="password" formClassName={classes.last_three_input}  />

      <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ type: 'USR' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
       
        <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  />
        <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input}/>
        {/* <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
            <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />                  
         </ReferenceInput> */}
 
        <ReferenceInput  perPage={2000} label="Approver" source="approverId" reference="users" formClassName={classes.one_three_input} sort={{ field: 'userName', order: 'ASC' }}>
              <SelectInput optionText="userName" validate={[required()]} />
      </ReferenceInput>
        
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  formClassName={classes.two_three_input}  />
        
        <FormDataConsumer formClassName={classes.last_three_input}>
          {({ formData, ...rest }) =>
            formData.typeId &&
            formData.typeId === 10 &&
             (
              <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" formClassName={classes.last_three_input} variant="outlined" >
                <SelectInput optionText="name"  validate={[required()]}  variant="outlined" formClassName={classes.last_three_input} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        
      </SimpleForm>
    </Create>
  );
};

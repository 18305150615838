import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import * as apiClient from "../../common/apiClient";

export const SSLogHeadersEdit = (props) => {
  //console.log(props, "====EDIT===");
  const [result, setResult] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  let headerId = props.id;

  const getHeaderRecords = () => {
    apiClient.getSSRecords(headerId).then(res => {
      //console.log(res, "====RESULT====");
      var keys = Object.keys(res[0]);
      keys.shift();
      keys.shift();
      setHeader(keys);
      setResult(res);
    }).catch(err => {
      console.log(err, "ERROR on fetching records");
      setError(err);
    });
  }
  const properCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  useEffect(() => {
    if (!headerId) return;
    getHeaderRecords()
  }, [headerId]);
  return (
    <>
      {isProcessing && (
        <div className='page-loader-container'>
          <div className='loader'>Loading...</div>
        </div>
      )}
      {<div className='errormessage'>{error}</div>}
      {!isProcessing && (
        <table className='employees'>
          {result && result.length > 0 && (
            <thead>
              <tr>
                {header &&
                  header.length > 0 &&
                  header.map((item, index) => {
                    return (
                      <th key={index} className={item}>
                        {properCase(item)}
                      </th>
                    );
                  })}
              </tr>
            </thead>
          )}
          <tbody>
            {result &&
              result.length > 0 &&
              result.map((item, index) => {
                return (
                  <tr key={index}>
                    {header &&
                      header.length > 0 &&
                      header.map((headerItem, HeaderIndex) => {
                        if (
                          (headerItem == "UpdStatus" &&
                            item[headerItem] != "Success") ||
                          (headerItem == "InsertStatus" &&
                            item[headerItem] != "No Error")
                        )
                          return (
                            <td
                              className='UpdStatus text-red'
                              key={HeaderIndex}
                            >
                              {item[headerItem]}
                            </td>
                          );
                        else
                          return (
                            <td className={headerItem} key={HeaderIndex}>
                              {item[headerItem]}
                            </td>
                          );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

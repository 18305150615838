import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../../common/apiClient";
import GrandTotal from './GrandTotal'
import AmountInWords from './AmountInWords'
import BankDetail from './BankDetail'
import BankAccountDetail from './BankAccountDetail'
import Signature from './Signature'
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

const styles = StyleSheet.create({
  page: {
    paddingTop: 120,
    paddingBottom: 50,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,

  },

  invoiceHeader: {
    fontSize: 14,
    height: 20,
    textAlign: 'center',
    fontWeight: "bold",
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    flexDirection: "column",

  },
  partyBox:
  {
    flexDirection: "row",
    height: "100",
    border: 1,
    paddingLeft: 10
  },
  partyBoxAddress:
  {
    flexDirection: "column",
    height: "100",
    width: "70%",


  },
  partyBoxAddress1:
  {
    height: "70%",
    width: "100%",
    fontSize: 12,
    paddingTop: 10,
  },
  billingMonth:
  {
    height: "30%",
    width: "100%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 10

  },
  invoiceDetail:
  {
    flexDirection: "column",
    height: "100",
    borderLeft: 1,
    width: "30%",
    fontSize: 11
  },
  invoiceDetailbox1:
  {
    height: "20%",
    borderBottom: 1,
    textAlign: "center",
  },
  invoiceDetailbox2:
  {
    height: "20%",
    textAlign: "center",
  },
  invoiceDetailDate:
  {
    height: "30%",
    textAlign: "center",
  },

  tableHeaderStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    height: "5%",
    width: "100%",
    fontSize: 11,
    borderBottom: 1,
    borderLeft: 1

  },
  tableHeaderStylebox1:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableHeaderStylebox2:
  {
    paddingTop: 3,
    textAlign: "center",
    height: "10%",
    width: "70%",

  },

  tableHeaderStylebox3:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "20%",
    borderLeft: 1,
    borderRight: 1
  },
  tableRow:
  {

    flexDirection: "column",
    width: "100%",
    borderBottom: 1,

  },
  tableRowStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: "3%"

  },
  tableRowStylebox1:
  {
    paddingRight: 5,
    paddingTop: 5,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableRowStylebox2:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "left",
    width: "70%",

  },
  tableRowStylebox3:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    paddingRight: 3,
    paddingTop: 5
  },
  tableRowStylebox2Total:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox3TotalAmount:
  {
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    paddingRight: 3,
    paddingTop: 5,
    fontSize: 13

  },
  tableRowStylebox2Tax:
  {
    flexDirection: "row",
    paddingRight: 0,
    paddingTop: 0,
    paddingLeft: 0,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2TaxEmptyBox:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2Taxdetail:
  {
    paddingRight: 3,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 11,
    borderLeft: 1,
    borderRight: 0,
    borderTop: 1,
    borderBottom: 1,


  },
  tableRowStylebox3Taxamount:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    paddingRight: 3,
    paddingTop: 50
  },
  tableRowStylewith5height:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: 5

  },
  tableHeaderStylebox2GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    height: "10%",
    width: "70%",
    fontSize: 13

  },
  tableHeaderStylebox3GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    fontSize: 13
  },
  boxAmountinWords:
  {
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    height: 30,
    fontSize: 11
  },
  bankDetailBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 30,
    fontSize: 11
  },
  bankAccountDetailBox:
  {
    flexDirection: "row",

    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 15,
    fontSize: 11
  },
  bankAccountDetailBox1:
  {

    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "35%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox2:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox3:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "25%",
    borderRight: 1,
    borderTop: 0,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox4:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  signatureBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 100,
    fontSize: 11
  },

});


const InvoicePrint = props => {

  const [isLoading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState({ partyName: "RedPlus Society,", address: "Baghanjatin Station Road Kolkata-700032 West Bengal", invoiceNo: "HDFC/20-21/JAN/0392R", invoiceDate: "11.11.2021", billMonth: "Month Of December 2020", taxableAmount: "1000.00" });
  const [detailData, setDetailData] = useState([{ particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims .", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }]);
  const [footerDetail, setFooterDetail] = useState("");
  const [footerDetail1, setFooterDetail1] = useState("");
  useEffect(() => { LoadData(props.invoiceId); }, []);
  const LoadData = async (id) => {

    await apiClient.getInvoiceDataForPrint(id).then(res => {

      console.log(res)
      let data = [];

      debugger
      setHeaderData(res[0]);
      if(res[0].InvoiceDetails)
      {
        setDetailData(JSON.parse(res[0].InvoiceDetails));
      }
      
      // setDetailData(res.detailData);
      setLoading(false);

    }).catch(err => {
      setLoading(false);
      console.log(err);
    });

  }

  return (
    <React.Fragment>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
      </div>
      {isLoading === false ?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
            <Page size="A4" style={styles.page}>
              <View>
                <View style={styles.invoiceHeader} >
                  <Text>{headerData.ReportName}</Text>
                </View>
                <View style={styles.partyBox} >
                  <View style={styles.partyBoxAddress}>
                    <View style={styles.partyBoxAddress1}>
                      <Text>To,</Text>
                      <Text>{headerData.partyName}</Text>
                      <Text>{headerData.address}</Text>
                    </View>
                    <View style={styles.billingMonth}>
                      <Text>{headerData.billMonth}</Text>

                    </View>
                  </View>
                  <View style={styles.invoiceDetail}>
                    <View style={styles.invoiceDetailbox1}>
                      <Text>Invoice No.</Text>
                    </View>
                    <View style={styles.invoiceDetailbox1}>
                      <Text>{headerData.InvoiceNo}</Text>
                    </View>
                    <View style={styles.invoiceDetailbox2}>
                      <Text>Invoice Date</Text>
                    </View>
                    <View style={styles.invoiceDetailDate}>
                      <Text>{headerData.InvoiceDate}</Text>
                    </View>
                  </View>


                </View>
                <View style={styles.tableHeaderStyle}>
                  <View style={styles.tableHeaderStylebox1}>
                    <Text> SNO.</Text>
                  </View>
                  <View style={styles.tableHeaderStylebox2}>
                    <Text>Particulars</Text>
                  </View>
                  <View style={styles.tableHeaderStylebox3}>
                    <Text>Amount</Text>
                  </View>

                </View>
                <View style={styles.tableRow}>
                  {detailData.map((item, index) => {
                    return <View key={index} style={styles.tableRowStyle}>
                      <View style={styles.tableRowStylebox1}>
                        <Text> {index + 1}</Text>
                      </View>
                      <View style={styles.tableRowStylebox2}>
                        <Text>{item.Particulars}</Text>
                      </View>
                      <View style={styles.tableRowStylebox3}>
                        <Text>{item.Amount?item.Amount.toFixed(2):"0.00"}</Text>
                      </View>
                    </View>
                  })}


{detailData.length===1 &&
                  <View key={-1} style={styles.tableRowStyle}>
                      <View style={styles.tableRowStylebox1}>
                        <Text> </Text>
                      </View>
                      {headerData.ReportFooter?
                      <View style={styles.tableRowStylebox2}>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                      </View>
                      :
                      <View style={styles.tableRowStylebox2}>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                      </View>
                      }
                      <View style={styles.tableRowStylebox3}>
                        
                      </View>
                    </View>
                  
                  }
                   {detailData.length===2 &&
              
                  <View key={-1} style={styles.tableRowStyle}>
                      <View style={styles.tableRowStylebox1}>
                        <Text> </Text>
                      </View>
                      {headerData.ReportFooter?
                      <View style={styles.tableRowStylebox2}>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                      </View>
                      :
                      <View style={styles.tableRowStylebox2}>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text  >{" "}</Text>
                        <Text  >{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text  >{" "}</Text>
                        <Text  >{" "}</Text>
                        <Text>{" "}</Text>
                        <Text>{" "}</Text>
                        <Text  >{" "}</Text>
                        <Text  >{" "}</Text>
                      </View>
                      }
                      <View style={styles.tableRowStylebox3}>
                        
                      </View>
                    </View>
                  
                  }
                  {headerData.ReportFooter?
                  <View style={styles.tableRowStylewith5height}>
                    <View style={styles.tableRowStylebox1}>
                      <Text> </Text>
                    </View>
                    <View style={styles.tableRowStylebox2Total}>
                      <Text>{headerData.ReportFooter?"Taxable Amount":""}</Text>
                    </View>
                    <View style={styles.tableRowStylebox3TotalAmount}>
                      <Text>{headerData.TaxableAmount?headerData.TaxableAmount.toFixed(2):"0.00"}</Text>
                    </View>
                  </View>:<View></View>}

                  {/* Tax Row  */}
                  {headerData.ReportFooter?
                  <View style={styles.tableRowStylewith5height}>
                    <View style={styles.tableRowStylebox1}>
                      <Text> </Text>
                    </View>
                    <View style={styles.tableRowStylebox2Total}>
                      <Text>Add:</Text>
                    </View>
                    <View style={styles.tableRowStylebox3}>
                      <Text></Text>
                    </View>
                    </View>:<View></View>}

                    {headerData.ReportFooter?
                  <View style={styles.tableRowStyle}>
                    <View style={styles.tableRowStylebox1}>
                      <Text> </Text>
                    </View>

                    <View style={styles.tableRowStylebox2Tax}>
                      <View style={styles.tableRowStylebox2TaxEmptyBox} />
                      <View style={styles.tableRowStylebox2Taxdetail} >
                        
                       {!headerData.IsCenteralHosp &&
                        <View style={{ flexDirection: "row" }} >
                          <View style={{ width: "10%" }} >
                            <Text> CGST @</Text>
                          </View>
                          <View style={{ width: "35%" }} >
                            <Text>  {headerData.CGSTPer?headerData.CGSTPer.toFixed(2):"0.00"}%</Text>
                          </View>
                          <View style={{ width: "55%" }} >
                            <Text> {headerData.CGSTAmt?headerData.CGSTAmt.toFixed(2):"0.00"}</Text>
                          </View>
                        </View>
 }
                        <Text>{" "}</Text>
                        {!headerData.IsCenteralHosp && <View style={{ flexDirection: "row" }} >
                          <View style={{ width: "10%" }} >
                            <Text> SGST @</Text>
                          </View>
                          <View style={{ width: "35%" }} >
                            <Text>   {headerData.SGSTPer?headerData.SGSTPer.toFixed(2):"0.00"}%</Text>
                          </View>
                          <View style={{ width: "55%" }} >
                            <Text> {headerData.SGSTAmt?headerData.SGSTAmt.toFixed(2):"0.00"}</Text>
                          </View>
                        
                        </View>

                        }
                        {headerData.IsCenteralHosp && <View style={{ flexDirection: "row" }} >
                          <View style={{ width: "10%" }} >
                            <Text> IGST @</Text>
                          </View>
                          <View style={{ width: "35%" }} >
                            <Text>   {headerData.IGSTPer?headerData.IGSTPer.toFixed(2):"0.00"}%</Text>
                          </View>
                          <View style={{ width: "55%" }} >
                            <Text> {headerData.IGSTAmt?headerData.IGSTAmt.toFixed(2):"0.00"}</Text>
                          </View>
                        
                        </View>

                        }
                      </View>
                    </View>
                    <View style={styles.tableRowStylebox3Taxamount}>
                      <Text>{headerData.taxAmount?headerData.taxAmount.toFixed(2):"0.00"}</Text>
                    </View>
                    </View>:<View></View>}

                  {/* //total */}
                  {headerData.ReportFooter?
                  <View style={styles.tableRowStylewith5height}>
                    <View style={styles.tableRowStylebox1}>
                      <Text> </Text>
                    </View>
                    <View style={styles.tableRowStylebox2Total}>
                      <Text></Text>
                    </View>
                    <View style={styles.tableRowStylebox3}>
                      <Text>{headerData.TotalAmount?headerData.TotalAmount.toFixed(2):"0.00"}</Text>
                    </View>
                    </View>:<View></View>}

                  <View style={styles.tableRowStylewith5height}>
                    <View style={styles.tableRowStylebox1}>
                      <Text> </Text>
                    </View>
                    <View style={styles.tableRowStylebox2Total}>
                      <Text>Rounded Off</Text>
                    </View>
                    <View style={styles.tableRowStylebox3}>
                      <Text>{headerData.roundOffAmount?headerData.roundOffAmount:"0.00"} </Text>
                    </View>
                  </View>


                </View>
                <GrandTotal styles={styles} amount={headerData.finalAmount} />
                <AmountInWords styles={styles} amountInWord={headerData.amountinWord} />
                <BankDetail styles={styles} />
                <BankAccountDetail styles={styles} data={headerData} />
                <Signature styles={styles} data={headerData} />
              </View>
            </Page>
          </Document>
        </PDFViewer> : null}
    </React.Fragment>
  );
}

export default InvoicePrint;
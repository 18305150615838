import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const RealizationOrderOtherFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});


    const onAmountChangejDisAllAmt = (e) => {
        let jDisAllAmt = parseInt(e.target.value);
        let { claimAmt, disAllAmt, uDisAllAmt, reclRecAmt } = formData;

        let varuDisAllAmt = disAllAmt - jDisAllAmt;
        let per = Math.round((varuDisAllAmt / (claimAmt + jDisAllAmt)) * 100);
        form.change("uDisAllAmt", varuDisAllAmt);
        form.change("uDisAllAmtPer", per);
    }


    return (
        <div className="payment-field-parent">



            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.billRDt == null) {
                        form.change("billRDt", new Date());
                        alert("File submission date cannot be blank.");
                    }
                }}
                label="Bill Raised ?" source="billRaised" fullWidth={true} className={classes.start_thrice_4_input} 
                disabled={rest.hasRealizationEdit}
                />
            <DateInput variant="outlined" disabled={(formData.billRaised == true && rest.hasRealizationEdit==false ) ? false : true} label="Bill Raised Date" source="billRDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined" disabled={rest.hasRealizationEdit}
                onChange={(e) => {
                    if (e && formData.rInvDt == null) {
                        form.change("invRDt", new Date());
                    }
                }}
                label="Invoice Raised ?" source="invRaised" fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.invRaised == true && rest.hasRealizationEdit==false ) ? false : true} label="Invoice Raised Date" source="invRDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined"
                onChange={(e) => {
                    if (e && formData.InvRDt == null) {
                        form.change("rBillRDt", new Date());
                    }
                }}
                label="Reclaim Bill Raised ?" source="rBilRaised" disabled={rest.hasRealizationEdit} fullWidth={true} className={classes.start_thrice_4_input} />
            <DateInput variant="outlined" disabled={(formData.rBilRaised == true && rest.hasRealizationEdit==false ) ? false : true} label="Reclaim Bill Raised Date" source="rBillRDt" fullWidth={true} className={classes.last_4_input} />

            <BooleanInput variant="outlined" label="Disputed In-Conclusive " source="isDspInCon" fullWidth={true} className={classes.start_thrice_4_input} disabled={rest.hasRealizationEdit}/>

            <BooleanInput variant="outlined" disabled={(formData.billRaised == true  && rest.hasRealizationEdit==false) ? false : true} 
                onChange={(e) => {
                    if (e && formData.billPRcvddt == null) {
                        form.change("billPRcvddt", new Date());
                    }
                }}
                label="Bill Received ?" source="billPRcvd" fullWidth={true} className={classes.one_three_input} 
                
                />
            <DateInput variant="outlined" disabled={(formData.billPRcvd == true && rest.hasRealizationEdit==false) ? false : true} label="Bill Raised Date" source="billPRcvddt" 
            fullWidth={true} className={classes.two_three_input} />
            <TextInput variant="outlined" disabled={(formData.billPRcvd == true && rest.hasRealizationEdit==false) ? false : true}  label="NEFT details" source="billPNeftDet" fullWidth={true}  className={classes.last_three_input}  />

            <BooleanInput variant="outlined"  disabled={(formData.rBilRaised == true && rest.hasRealizationEdit==false) ? false : true} 
                onChange={(e) => {
                    if (e && formData.rBillPRcvdDt == null) {
                        form.change("rBillPRcvdDt", new Date());
                    }
                }}
                label="Reclaim Bill Received ?" source="rBillPRcvd" fullWidth={true} className={classes.one_three_input} />
            <DateInput variant="outlined" disabled={(formData.rBillPRcvd == true  && rest.hasRealizationEdit==false) ? false : true} label="Reclaim Bill Raised Date" source="rBillPRcvdDt" 
            fullWidth={true} className={classes.two_three_input} 
            
            />
            <TextInput variant="outlined" disabled={(formData.rBillPRcvd == true  && rest.hasRealizationEdit==false) ? false : true}  label="NEFT details" source="rBillPNeftDet" fullWidth={true}  className={classes.last_three_input}  />
           
        </div>
    )
}

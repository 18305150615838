import React, { useState, useEffect } from 'react';
import {
    Edit, Create, SimpleForm, TextInput, ReferenceInput,
    AutocompleteInput, DateInput, BooleanInput,
    SelectInput, required, FileInput, FileField
} from 'react-admin';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";

export const ImportFile = props => {
    const [sections, setSection] = useState<any>([]);
    const [saving, setSaving] = useState(false);
    const defaultValues = { class_id: -1, };
    const [result, setResult] = useState<any[]>([]);
    const [header, setHeader] = useState<any[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (formdata) => {
        setIsProcessing(true);
        setError("");
        let isV = 0;
        if (formdata.isVerified) {
            isV = 1;
        }
        let userid = localStorage.getItem("userId");
        let d = new Date();
        if (formdata.importDate)
            d = new Date(formdata.importDate);

        let mnt = d.getMonth() + 1;
        setIsVerified(formdata.isVerified);
        apiClient
            .UploadImportFile("importfile", formdata.importfile, formdata.hospitalId, mnt, d.getFullYear(), userid, isV)
            .then((res) => {
                console.log(res);
                console.log(res.response);
                if (res.result == "Success") {
                    if (!formdata.isVerified) {
                        var keys = Object.keys(res.response[0]);
                        setHeader(keys);
                        setResult(res.response);
                    } else {
                        setError("Data uploaded successfully");
                    }

                } else {
                    let message = res.response.originalError.info.message;
                    setError(message);
                }

                setIsProcessing(false);
            })
            .catch((err) => {
                console.log(err);
                setIsProcessing(false);
            });

    }
    useEffect(() => {

    }, []);
    return (
        <>
            <Create title="Import File" basePath="importfile" resource="importfile" {...props}>
                <SimpleForm
                    onSubmit={handleSubmit}
                    toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    initialValues={defaultValues}
                    variant="outlined">

                    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true}>
                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>

                    {/* <SelectInput
                fullWidth={true}
                    label='Month'
                    source='monthid'
                    choices={[
                        { id: 1, name: "January" },
                        { id: 2, name: "February" },
                        { id: 3, name: "March" },
                        { id: 4, name: "April" },
                        { id: 5, name: "May" },
                        { id: 6, name: "June" },
                        { id: 7, name: "July" },
                        { id: 8, name: "August" },
                        { id: 9, name: "September" },
                        { id: 10, name: "October" },
                        { id: 11, name: "November" },
                        { id: 12, name: "December" },
                    ]}
                /> */}


                    <DateInput source="importDate" label="Date" />
                    <BooleanInput source="isVerified" label="Is Verified" />

                    <FileInput source="importfile" label="Document">
                        <FileField source="importfile" title="Document" />
                    </FileInput>

                </SimpleForm>
            </Create>

            {isProcessing && (
                <div className='page-loader-container'>
                    <div className='loader'>Loading...</div>
                </div>
            )}
            {<div className='errormessage'>{error}</div>}
            {!isProcessing && (
                <table className='employees'>
                    {result && result.length > 0 && (
                        <thead>
                            <tr>
                                {header &&
                                    header.length > 0 &&
                                    header.map((item, index) => {
                                        return (
                                            <th key={index} className={item}>
                                                {item}
                                            </th>
                                        );
                                    })}
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {result &&
                            result.length > 0 &&
                            result.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        {header &&
                                            header.length > 0 &&
                                            header.map((headerItem, HeaderIndex) => {
                                                if (
                                                    (headerItem == "UpdStatus" &&
                                                        item[headerItem] != "Success") ||
                                                    (headerItem == "InsertStatus" &&
                                                        item[headerItem] != "No Error")
                                                )
                                                    return (
                                                        <td
                                                            className='UpdStatus text-red'
                                                            key={HeaderIndex}
                                                        >
                                                            {item[headerItem]}
                                                        </td>
                                                    );
                                                else
                                                    return (
                                                        <td className={headerItem} key={HeaderIndex}>
                                                            {item[headerItem]}
                                                        </td>
                                                    );
                                            })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            )}

        </>
    );
}
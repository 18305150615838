import React, { useState, useEffect } from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,

  SimpleForm,
  FormTab,
  useNotify,
  AutocompleteInput,
  DateInput,
  useRedirect
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as apiClient from "../../common/apiClient";
import exportFromJSON from 'export-from-json'
export const HospitalInvoiceCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const [invoiceData, setInvoiceData] = useState([])
  const [hospitalTaxPer, setHospitalTaxPer] = useState(0)
  const [hospitalId, setHospitalId] = useState(0)
  const [hospitalTaxTypeId, setHospitalTaxTypeId] = useState(0)
  const [invoiceAmount, setinvoiceAmount] = useState(0)
  const [invoiceTaxAmount, setInvoiceTaxAmount] = useState(0)
  const [invoiceRoundOfAmount, setInvoiceRoundOfAmount] = useState(0)
  const [invoiceGrandTotal, setInvoiceGrandTotal] = useState(0)
  const [invTypeData, setinvTypeData] = useState([{ id: '43', name: 'CLAIM' }, { id: '44', name: 'RECLAIM' }, { id: '45', name: 'Tie-Up' }, { id: '46', name: 'Desk Management' }, { id: '47', name: 'FreeHand' }, { id: '50', name: 'Remarks NP' }, { id: '51', name: 'SS Claim' }])
  const [billYearFrom, setBillYearFrom] = useState(0);
  const [billYearTo, setBillYearTo] = useState(0);


  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const monthID = new Date().getMonth() + 1;

    if (monthID === 1 || monthID === 2 || monthID === 3) {
      setBillYearFrom(currentYear - 1);
      setBillYearTo(currentYear + 1);
    } else {
      setBillYearFrom(currentYear);
      setBillYearTo(currentYear + 1);
    }
  }, []);

  useEffect(() => {
    setInvoiceData([{ hospitalId: localStorage.getItem("HospitalId"), invTypeId: 0, hospitalInvId: 1, realStartDate: "", realEndDate: "", particulars: "", totalAmount: 0, totalServicePerc: 0, totalServiceAmount: 0 }])
  }, []);

  const loadhospitaldata = async (id) => {
    let taxPer = 0;
    setHospitalTaxPer(0)
    setHospitalTaxTypeId(0)
    await apiClient.gethospitaldata(id).then(res => {
      if (res.length) {
        setHospitalTaxPer(res[0].taxPercentage)
        setHospitalTaxTypeId(res[0].TaxtTypeID)
        taxPer = res[0].taxPercentage
      }
    }).catch(err => {
      console.log(err);
    });
    return taxPer;
  }
  const onactionChange = async (e) => {
    if (e.target.value == 49) {
      setHospitalTaxPer(0);
      setHospitalTaxTypeId(e.target.value)
      let data = [...invoiceData]
      setInvoiceData([...data])
      calulateTotal2([...data], 0);
    }
    else {
      let taxPer = await loadhospitaldata(hospitalId);

      let data = [...invoiceData]
      setInvoiceData([...data])
      calulateTotal2([...data], taxPer);
    }

    //alert(e.target.value);
  };
  const loadInvTypeCalculation = (hospitalId, invTypeId, fromdate, todate, index) => {

    let data = [...invoiceData]
    data[index].totalServiceAmount = 0;
    data[index].totalServicePerc = 0;
    data[index].totalAmount = 0;
    data[index].particulars = "";
    setInvoiceData([...data])
    apiClient.getRealizord_BillingPerc_ByHospDateRange(hospitalId, fromdate, todate, invTypeId).then(res => {
      if (res.length === 0) { notify("No claim found", "warning") }
      else {
        let data = [...invoiceData]
        data[index].totalServiceAmount = res[0].TotalServiceAmount;
        data[index].totalServicePerc = res[0].TotalServicePerc;
        data[index].totalAmount = res[0].TotalAmount;
        data[index].particulars = res[0].Particulars;
        setInvoiceData([...data])
        calulateTotal([...data]);
      }


    }).catch(err => {
      alert(err)
      console.log(err);
    });
  }
  const handleRemove = async (index) => {
    let data = [...invoiceData]
    data.splice(index, 1);
    setInvoiceData([...data])
    calulateTotal([...data])
  }
  const handleDownload = async (e, item) => {
    e.preventDefault();
    if (item.realStartDate == null || item.realStartDate == undefined || item.realStartDate == '') {
      notify("Please Select From Date", "warning")
      return
    }
    if (item.realEndDate == null || item.realEndDate == undefined || item.realEndDate == '') {
      notify("Please Select To Date", "warning")
      return
    }
    apiClient.getRealizord_BillingPerc_ByHospDateRangeDetail(hospitalId, item.realStartDate, item.realEndDate, item.invTypeId).then(res => {
      if (res.length === 0) { notify("No claim found", "warning") }
      else {
        exportToCSV(res);
      }


    }).catch(err => {
      alert(err)
      console.log(err);
    });


  }
  function exportToCSV(data) {
    const fileName = 'download'
    const exportType = 'xls'
    exportFromJSON({ data, fileName, exportType })

  }
  const handleAdd = async (index) => {
    let data = [...invoiceData]
    data.push({ hospitalId: localStorage.getItem("HospitalId"), hospitalInvId: 1, invTypeId: 0, realStartDate: "", realEndDate: "", particulars: "", totalAmount: 0, totalServicePerc: 0, totalServiceAmount: 0 })
    setInvoiceData([...data])
  }

  const handleChange = async (event, index, identifier) => {
    if (identifier == "hospital") {
      alert(event.target.value);
      return
    }
    let data = [...invoiceData]
    data[index][identifier] = event.target.value;
    if (identifier === 'invTypeId') {
      data[index]["totalAmount"] = 0;
      data[index]["realStartDate"] = "";
      data[index]["realEndDate"] = "";
    }
    setInvoiceData([...data])
    if ((identifier === 'realStartDate') || (identifier === 'realEndDate')) {
      let payload = {}
      payload.fromdate = data[index].realStartDate
      payload.todate = data[index].realEndDate
      if (identifier === 'realStartDate') {
        payload.fromdate = event.target.value
      }
      if (identifier === 'realEndDate') {
        payload.todate = event.target.value
      }

      let filterinvTypeData = invTypeData.filter(x => x.id === data[index].invTypeId);

      if (filterinvTypeData.length && (filterinvTypeData[0].name === 'CLAIM' || filterinvTypeData[0].name === 'RECLAIM' || filterinvTypeData[0].name === 'Tie-Up' || filterinvTypeData[0].name === 'Desk Management')) {
        payload.invTypeId = filterinvTypeData[0].name;
        if (payload.fromdate.length && payload.todate.length)
          loadInvTypeCalculation(hospitalId, payload.invTypeId, payload.fromdate, payload.todate, index);
      }


    }
    calulateTotal([...data]);

  }
  const handleSubmit = async (formdata) => {
    console.log(formdata);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }

    if (formdata.billYearFrom > formdata.billYearTo) {
      notify("Please Valid Bill Year From", "warning")
      return;
    }
    let IsValid = true;
    let errormessage = "";
    for (let index = 0; index < invoiceData.length; index++) {

      if (invoiceData[index].invTypeId <= 0) {
        errormessage = `Please Select Invoice Type at row no ${index + 1}`
        IsValid = false;
        break
      }
      if (invoiceData[index].realStartDate === "") {
        errormessage = `Please Select Date From at row no ${index + 1}`
        IsValid = false;
        break
      }
      if (invoiceData[index].realEndDate === "") {
        errormessage = `Please Select Date To at row no ${index + 1}`
        IsValid = false;
        break
      }
      if (invoiceData[index].particulars === "") {
        errormessage = `Please Enter Particulars at row no ${index + 1}`
        IsValid = false;
        break
      }
      if (invoiceData[index].invTypeId != '47' || invoiceData[index].invTypeId != 47) {
        if (invoiceData[index].totalAmount < 0) {
          errormessage = `Please Enter Amount  at row no ${index + 1}`
          IsValid = false;
          break
        }
      }

    }
    if (!IsValid) {
      notify(errormessage, "warning")
      return;
    }


    let data = {};
    data.invoiceId = 0;
    data.hospitalId = hospitalId;
    data.taxtypeId = hospitalTaxTypeId;
    data.taxPer = hospitalTaxPer;
    data.billYearFrom = formdata.billYearFrom;
    data.billYearTo = formdata.billYearTo;
    data.billMonth = formdata.billMonthId;
    data.perfInvoiceDate = formdata.perfInvoiceDate;
    data.isGenerateInv = 0;
    data.invoiceDate = formdata.perfInvoiceDate;
    data.invoiceAmount = invoiceAmount;
    data.taxAmount = invoiceTaxAmount;
    data.roundOffAmount = invoiceRoundOfAmount;
    data.totalAmount = invoiceGrandTotal;
    data.userId = user;
    data.invoiceDetail = [...invoiceData];
    data.paymentrcvd = 0;
    data.paymentrcvddt = "";
    data.NeftDetails = "";

    apiClient.addupdateHospitalInvoice(data).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/hospital-invoice");
      }
      else {
        notify(res[0].message)
      }


    }).catch(err => {
      notify("unable to add Invoice", "warning")
      console.log(err);
    });
  }
  const calulateTotal2 = async (invData, taxper) => {
    debugger
    let data = [...invData]
    let invoiceAmount = 0
    let invoiceTaxAmount = 0
    let invoiceRoundOff = 0
    let invoiceFinalAmount = 0
    data.forEach((Element) => {
      invoiceAmount = parseFloat(invoiceAmount) + parseFloat(Element.totalAmount)
    })
    invoiceTaxAmount = (invoiceAmount * taxper * .01)
    invoiceRoundOff = Math.round(invoiceAmount + invoiceTaxAmount) - (invoiceAmount + invoiceTaxAmount);

    invoiceFinalAmount = parseFloat(invoiceAmount) + parseFloat(invoiceTaxAmount) + parseFloat(invoiceRoundOff);
    setinvoiceAmount(invoiceAmount.toFixed(2));
    setInvoiceTaxAmount(invoiceTaxAmount.toFixed(2));
    setInvoiceRoundOfAmount(invoiceRoundOff.toFixed(2))
    setInvoiceGrandTotal(invoiceFinalAmount.toFixed(2));


  }
  const calulateTotal = async (invData) => {
    debugger
    let data = [...invData]
    let invoiceAmount = 0
    let invoiceTaxAmount = 0
    let invoiceRoundOff = 0
    let invoiceFinalAmount = 0
    data.forEach((Element) => {
      invoiceAmount = parseFloat(invoiceAmount) + parseFloat(Element.totalAmount)
    })
    invoiceTaxAmount = (invoiceAmount * hospitalTaxPer * .01)
    invoiceRoundOff = Math.round(invoiceAmount + invoiceTaxAmount) - (invoiceAmount + invoiceTaxAmount);

    invoiceFinalAmount = parseFloat(invoiceAmount) + parseFloat(invoiceTaxAmount) + parseFloat(invoiceRoundOff);
    setinvoiceAmount(invoiceAmount.toFixed(2));
    setInvoiceTaxAmount(invoiceTaxAmount.toFixed(2));
    setInvoiceRoundOfAmount(invoiceRoundOff.toFixed(2))
    setInvoiceGrandTotal(invoiceFinalAmount.toFixed(2));


  }

  return (
    <Create title="Hospital Invoice " {...props}>
      <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} onSave={handleSubmit} />} onSubmit={handleSubmit} >

        <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]}
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.one_4_input}>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" fullWidth={true} filter={{ type: 'TT' }} reference="lookups" formClassName={classes.two_4_input} onChange={onactionChange}    >
          <SelectInput optionText="name" value={hospitalTaxTypeId} />
        </ReferenceInput>
        <NumberInput label="Tax Percentage" source="taxPercentage" fullWidth={true} formClassName={classes.three_4_input} disabled value={hospitalTaxPer} />
        {billYearFrom > 0 && <SelectInput source="billYearFrom" label="Bill Year Form" validate={[required()]} fullWidth={true} formClassName={classes.last_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} disabled initialValue={billYearFrom} />}
        {billYearTo > 0 && <SelectInput source="billYearTo" label="Bill Year To" validate={[required()]} fullWidth={true} formClassName={classes.one_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} disabled initialValue={billYearTo} />}

        <SelectInput source="billMonthId" label="Bill Month" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input} choices={[{ id: '1', name: 'Jan' }, { id: '2', name: 'Feb' }, { id: '3', name: 'March' }, { id: '4', name: 'April' }, { id: '5', name: 'May' }, { id: '6', name: 'June' }, { id: '7', name: 'July' }, { id: '8', name: 'August' }, { id: '9', name: 'Sept' }, { id: '10', name: 'Oct' }, { id: '11', name: 'Nov' }, { id: '12', name: 'Dec' }]} />
        <DateInput validate={[required()]} label="Invoice Date" source="perfInvoiceDate" fullWidth={true} formClassName={classes.three_4_input} />

        {hospitalId > 0 &&
          <Table className={classes.customtable} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell >Invoice Type</TableCell>
                <TableCell>Date From</TableCell>
                <TableCell>Date To</TableCell>
                <TableCell>Particulars</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {invoiceData.map((item, index) => {

                return <tr >

                  <TableCell>


                    <select style={{ height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.invTypeId} onChange={(event) => handleChange(event, index, "invTypeId")} variant="outlined" source={"Col1" + index} label="Invoice Type" fullWidth={true} formClassName={classes.one_4_input}  >
                      <option value={'0'}>Select </option>
                      {invTypeData.map((item) => {

                        return <option selected={item.id === item.invTypeId} value={item.id}>{item.name}</option>
                      })}

                    </select>
                  </TableCell>
                  <TableCell>
                    {(item.invTypeId != '50' || item.invTypeId != 50) ? <input type="date" style={{ width: "122px", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.realStartDate} onChange={(event) => handleChange(event, index, "realStartDate")} variant="outlined" validate={[required()]} label="Invoice Date" source={"Col2" + index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()} /> : ""}
                  </TableCell>
                  <TableCell>
                    {(item.invTypeId != '50' || item.invTypeId != 50) ? <input type="date" style={{ width: "122px", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.realEndDate} onChange={(event) => handleChange(event, index, "realEndDate")} formClassName={classes.customInput} validate={[required()]} label="Invoice Date" source={"Col3" + index} fullWidth={true} formClassName={classes.two_4_input} onKeyDown={(e) => e.preventDefault()} /> : ""}
                  </TableCell>
                  <TableCell>
                    {/* <TextareaAutosize variant="outlined" rowsMax={2} value={item.particulars}  onChange={(event)=>handleChange(event,index,"particulars")}    aria-label="maximum height" placeholder="particulars" defaultValue=""  source={"Col4"+index}/> */}
                    <textarea style={{ height: "100px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} value={item.particulars} onChange={(event) => handleChange(event, index, "particulars")} aria-label="maximum height" placeholder="particulars" defaultValue="" source={"Col4" + index} />
                  </TableCell>
                  <TableCell>

                    {( item.invTypeId === 51 || item.invTypeId === 50 || item.invTypeId === '51' ||
                      item.invTypeId === 46 || item.invTypeId === 46 || item.invTypeId === '46'||
                      item.invTypeId === 45 || item.invTypeId === 45 || item.invTypeId === '45' || item.invTypeId === 50 || item.invTypeId === '50' || item.invTypeId === '43' || item.invTypeId === '43' || item.invTypeId === 43 || item.invTypeId === 43 || item.invTypeId === '44' || item.invTypeId === '51' || item.invTypeId === 44 || item.invTypeId === 51) ? <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" label="" source="taxPercentage" validate={[required()]} rowsMax={2} value={item.totalAmount} disabled source={"Col5" + index} />
                      : <input style={{ width: "100px", textAlign: "right", height: "35px", fontSize: "11px", "border": "1px solid #C4C4C4", "borderRadius": "5px" }} type="number" variant="outlined" label="" source="totalAmount" validate={[required()]} rowsMax={2} value={item.totalAmount} onChange={(event) => handleChange(event, index, "totalAmount")} source={"Col5" + index}

                      />
                    }
                    {" "} {index != 0 ? <button type="button" onClick={() => handleRemove(index)} >-</button> : null} {"  "}
                    {(invoiceData.length - 1) === index ? <button button type="button" onClick={() => handleAdd(index)}>+</button> : null}
                    {(item.invTypeId == 43 || item.invTypeId == 44) ?
                      <div><a href="#" onClick={(e) => handleDownload(e, item)}>Download Detail</a> </div>

                      : null}
                  </TableCell>
                </tr>
              })}
            </TableBody>
          </Table>
        }

        {hospitalId > 0 && <FormDataConsumer  >
          {formDataProps => (
            <div style={{ display: "block", "text-align": "-webkit-right" }}>
              <div>
                <table style={{ width: 300 }} >
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Invoice Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Total Tax Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceTaxAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Round-Off Amount :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceRoundOfAmount}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", fontSize: "11px" }} >Grand Total :</td>
                      <td style={{ textAlign: "right", fontSize: "11px" }} >{invoiceGrandTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          )}
        </FormDataConsumer>
        }
        <FormDataConsumer >
          {({ formData, ...rest }) => {

            setHospitalId(formData.hospitalId);
            if (formData.hospitalId != hospitalId) { loadhospitaldata(formData.hospitalId) }
            //  if(formData.hospitalTaxTypeId!=hospitalTaxTypeId)
            //  {
            //   loadhospitaldata(formData.hospitalId)
            //  }                               
          }
          }
        </FormDataConsumer>
      </SimpleForm>

    </Create>
  );
};

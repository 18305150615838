import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import CompanyDocuments from "./CompanyDocuments";

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
      props.setHospitalDetEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setHospitalDetId}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const ConfigsEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const [isaddTpa, setIsAddTpa] = useState(false);
  const [isEditTpa, setIsEditTpa] = useState(false);
  const [hospitalDetEditId, setHospitalDetEditId] = useState(0);
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const myclasses = myStyles({});
  const refresh = useRefresh();
  const setgridRecordEdit = (id) => {
   
    setHospitalDetEditId(id);
    
}
const closeEdit = () => {
  
  setIsAddDocuments(false);
  refresh();
}


const onCancel = () => {
  setIsAddTpa(false);
  setIsEditTpa(false);
  setIsAddDocuments(false);
}

  const handleClose = useCallback(() => {
    setIsAddTpa(false);
    //setIsAccountServiceAdd(false);
}, []);

  return (
    <React.Fragment>
    <Edit title="Config Edit" {...propsObj}>
    
   <TabbedForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label="Company Detail">
          
          <TextInput validate={[required()]} source="mconame" label="Company Name" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="comphd" label="Company Heading " fullWidth={true} formClassName={classes.last_inline_input} />

          <TextInput label="Address 1" source="add1"  fullWidth={true} />
          <TextInput label="Address 2" source="add2" fullWidth={true} />
          <TextInput label="Address 3" source="add3"  fullWidth={true}/>
          <TextInput source="phone" label="Phone" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput source="fax" label="Fax" fullWidth={true} formClassName={classes.last_inline_input} />
          
          <TextInput label="Other Address 1" source="delvadd1"  fullWidth={true} />
          <TextInput label="Other Address 2" source="delvadd2" fullWidth={true} />
          <TextInput label="Other Address 3" source="delvadd3"  fullWidth={true}/>
          <TextInput source="delvPhone" label="Other Phone" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput source="delvfax" label="Other Fax" fullWidth={true} formClassName={classes.last_inline_input} />
          
          <DateInput label="From Date" source="fromDate" fullWidth={true} formClassName={classes.one_4_input} />
          <DateInput label="To Date" source="toDate" fullWidth={true} formClassName={classes.two_4_input} />
          <NumberInput label="Closing Stock" source="closstock" fullWidth={true} formClassName={classes.three_4_input} />
          <DateInput label="Cur Date" source="curDate" fullWidth={true} formClassName={classes.last_4_input} />
          
          <TextInput label="LST NO" source="lstno" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput label="Tin" source="tin" fullWidth={true} formClassName={classes.two_4_input} />
          <TextInput label="Cst No" source="cstno" fullWidth={true} formClassName={classes.three_4_input} />
          <BooleanInput label="Is Active" source="isactive" fullWidth={true} formClassName={classes.last_4_input} />
          <TextInput label="Invoice Footer Text" source="invoiceFooterText" fullWidth={true} />

          
              
          </FormTab>
           
          <FormTab value="DETAILS" label="Realization Order Term">
        
          <TextInput source="salterms1" label=""  fullWidth={true}/>
          <TextInput source="salterms2" label=""  fullWidth={true}/>
          <TextInput source="salterms3" label=""  fullWidth={true}/>
          <TextInput source="salterms4" label=""  fullWidth={true}/>
          <TextInput source="salterms5" label=""  fullWidth={true}/>
          <TextInput source="salterms6" label=""  fullWidth={true}/>
          </FormTab>
          <FormTab label="Documents">
                        <Button variant="contained" onClick={() => { setIsAddDocuments(true); }}
                            color="secondary">
                            Add Document
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="configdets"
                            perPage={500}
                            target="configId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                                <TextField label="Document Type" source="docType" />
                                <TextField label="No of Pages" source="docValue" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
        </TabbedForm>


    </Edit>
    <Drawer
     anchor="right"
     onClose={handleClose}
     classes={{ paper: myclasses.drawerPaper, }}
     open={ isaddDocuments} >
       {isaddDocuments &&  <CompanyDocuments
                      onClose={closeEdit}
                      onCancel={onCancel}
                      configId={props.id}
                      {...props} />}
       
       </Drawer>
 
</React.Fragment>
  );
};

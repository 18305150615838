import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  AutocompleteInput,
  downloadCSV 
} from "react-admin";
import * as apiClient from "../../common/apiClient";
import jsonExport from 'jsonexport/dist';


const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Document No" source="importDocNo~like" alwaysOn />
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    <SearchInput variant="outlined" label="Year" source="yearId" alwaysOn />
  </Filter>
);

export const DownloadDetailRecords = (props) => {
  const { record } = props;
  const { id } = record;
  //console.log(id, "===DownloadDetailRecords====");

  const getHeaderRecords = () => {
    apiClient.getSSRecords(id).then(res => {
      jsonExport(res, (err, csv) => {
        downloadCSV(csv, 'SSDetail'); // download as 'posts.csv` file
    });     
    }).catch(err => {
      console.log(err, "ERROR on fetching records");

    });
  }

  return (
    <div className="download-csv-button" onClick={() => { getHeaderRecords(); }}>Download Data</div>
  )
}

export const SSLogHeadersList = (props) => {
  return (
    <List {...props} exporter={false} bulkActionButtons={false} title="SS Import List" filters={<Filters />}  >
      <Datagrid rowClick={false}>
        <EditButton label="View Records" />
        <DownloadDetailRecords label="Download" />
        <TextField source="importDocNo" label="Document Number" />
        <ReferenceField source="hospitalId" reference="hospitals" label="Hospital"><TextField source="name" /></ReferenceField>
        <TextField source="monthId" label="Month"></TextField>
        <TextField source="yearId" label="Year"></TextField>
        <BooleanField source="isVerified" label="Is Verified" />
        <NumberField source="noOfrecords" label="No Of Records" />
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" />

      </Datagrid>
    </List>
  );
};

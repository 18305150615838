export const Authorizer = (props, type?) => {


  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj = { ...propsObj, ...p };
      if (!propsObj.hasExport)
        propsObj.exporter = false;
      if (type) {
        propsObj.title = `${p.Module} ${type}`;
      }
      propsObj.bulkActionButtons = false;
    }
    //   if(!propsObj.hasDelete)
    // {
    //   propsObj.bulkActionButtons=false;
    // }
  }
  return propsObj;
}


import React, { useState, useEffect, useRef } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const Fields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});  

    return (
        <React.Fragment>
            <BooleanInput source="isApproved" label="Is Approved" onChange={()=>form.change("isReject", false)} />
            <BooleanInput source="isReject" label="Is Reject" onChange={()=>form.change("isApproved", false)} />
        </React.Fragment>
    )
}
export default Fields;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { lightTheme } from './themes';
import { Location } from 'history';
import logo from "../images/logo.png";
import * as apiClient from "../common/apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'teal',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '0em',
        height: 380,
        borderRadius: "5px 0px 0px 5px",
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    registerCard: {
        minWidth: 300,
        marginTop: '0em',
        height: 380,
        backgroundColor: "#8899AA",
        display: 'flex',
        justifyContent: "center",
        borderRadius: "0px 5px 5px 0px",
    },
    register: {
        padding: '1em',
        color: "#fff",
        display: 'flex',
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center"
    },
    registerText: {
        margin: '0px 15px 15px 15px',
        textAlign: "center",
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));
interface IMeta {
    touched: boolean;
    error: Error;
}

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
        <TextField variant="outlined"
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    );

interface FormValues {
    email?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = ({ location }: { location: Location }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles({});
    const notify = useNotify();
    const login = useLogin();
    let history = useHistory();

    const handleSubmit = (auth: FormValues) => {
        //debugger
        setLoading(true);
        // TEMP TO LOGIN//
        //localStorage.setItem('username', "test@gmail.com");
        //localStorage.setItem("userId", "123");
        //history.push("/");
        // TEMP TO LOGIN//
        
        let formdata={
            username:auth.email,
            password:auth.password,
            usertype:'admin'
        }
        apiClient.loginUser(formdata).then(res => {
            //debugger
            
            //localStorage.setItem("type", "admin");
            setLoading(false);
            //console.log(res);
            if (res[0].result==true) {
                
                let user = res[0];
                localStorage.setItem("firstname", user.UserName);
                localStorage.setItem("lastname", "");
                localStorage.setItem('username', user.UserName);
                localStorage.setItem("userId", user.ID);
                localStorage.setItem("type", user.RoleType);
                localStorage.setItem("displayName",user.UserName);
                localStorage.setItem("TypeID",user.TypeID);
                localStorage.setItem("RoleTypeId",user.RoleTypeId);
                localStorage.setItem("EmployeeId",user.EmployeeId);
                localStorage.setItem("ApproverId",user.ApproverId);
                localStorage.setItem("AuthType",user.AuthType);
                
                
                if(user.HospitalId)
                    localStorage.setItem("HospitalId",user.HospitalId);
                else
                    localStorage.setItem("HospitalId","");

                // apiClient.getmodules(user.ID).then(res => {
                //     localStorage.setItem("menu", JSON.stringify(res));
                //     //console.log(res);
                //     history.push("/");
                // }).catch(err => {
                //     console.log(err);
                // });
                
                 
                {
                     
                    apiClient.getmodules(user.ID).then(res => {
                        //Convert the Rights as Object
                        //console.log(res, "=====res=====");
                        let resArr = [...res];
                        resArr.forEach(element => {
                            if (element.ModuleRights != undefined) {
                                let mrArr = element.ModuleRights.split(",");
                                mrArr.forEach(r => {
                                    let rArr = r.split("|");
                                    element[rArr[1]] = (parseInt(rArr[2])) ? true : false;
                                });
                                element["hasList"] = element["hasView"];
                                element["hasShow"] = element["hasView"];
                                // Remove these after permissions corrected
                                // element["View"] = element["hasView"];
                                // element["Edit"] = element["hasEdit"];
                                // element["Delete"] = element["hasDelete"];
                                // element["Create"] = element["hasCreate"];
                                // element["Export"] = element["hasExport"];
                                // element["hasView"] = element["hasView"];
                            }

                        });
                        //console.log(resArr, "=====resArr=====");
                        localStorage.setItem("menu", JSON.stringify(resArr));
                        //console.log(res, "===getmodules===");
                        if(user.AuthType!="1")
                        {
                            history.push("/");
                        }
                        else
                        {
                            history.push("/realiz-ords");
                            
                        }
                            
                            window.location.reload()
                        
                    }).catch(err => {
                        console.log(err);
                    });
                }
                // history.push("/");
                //localStorage.setItem("Ownrecords", user.Ownrecords);
                // apiClient.getmodules(user.userId).then(res => {
                //     localStorage.setItem("menu", JSON.stringify(res));
                //     //console.log(res);
                    // history.push("/");
                // }).catch(err => {
                //     console.log(err);
                // });
                
            } else {
                notify(res[0].message, 'warning');
            }
        }).catch(err => {
            setLoading(false);
            notify(err, 'warning');
        });
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>

                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                            </CardActions>
                        </Card>
                        <Card className={classes.registerCard}>
                            <div className={classes.register}>
                                <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <HowToRegIcon />
                                    </Avatar>
                                </div>
                                <div className={classes.registerText}>
                                    <img style={{height: "84px"}} src={logo} />
                                </div>
                            </div>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;

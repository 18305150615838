import CircularProgress from "@material-ui/core/CircularProgress"; 
import {PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {Table,TableHeader,TableCell,TableBody,DataTableCell} from '@david.kucsai/react-pdf-table'
import React from 'react'
const AmountInWords = (props) => {
    let styles=props.styles
return <View style={styles.boxAmountinWords} >
<Text>Amount Chargable (in Words):</Text> 
<Text style={{fontWeight:"bold"}}>{props.amountInWord}</Text> 
 </View>
}
export default AmountInWords
// Rupees Thirty Four Thousand Eight Hundred and Fifity Seven Only 
import React, { useState, useEffect } from "react";

import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,

  SimpleForm,
  FormTab,
  useNotify,
  AutocompleteInput,
  DateInput,
  useRedirect
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as apiClient from "../../common/apiClient";



export const SalaryCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles({});
  const [salaryData, setSalaryData] = useState([])
  const [slicedSalaryData, setSlicedSalaryData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [headerData, setHeaderData] = useState([])
  const [id, setId] = useState(0)
  const [salaryYear, setSalaryYear] = useState('')
  const [salaryMonth, setSalaryMonth] = useState('')
  const [serachText, setSerachText] = useState("")

  useEffect(() => {

  }, [slicedSalaryData]);
  const loaddata = (year, month) => {
    if (year != null && year != undefined && year != '' && month != null && month != undefined && month != '')
      apiClient.getsalary(id, month, year).then(res => {
        debugger
        setSalaryData(res);
        let d = JSON.parse(res[0]["HeaderDeatils"])
        let data = [];
        for (let index = 0; index < res.length; index++) {
          if (index < (pageSize * currentPage)) {
            const element = res[index];
            data.push(element);
          }
          setSlicedSalaryData([...data]);
          setTotalPage(Math.ceil(res.length / pageSize));



        }
        setHeaderData({ ...d[0] });
      }).catch(err => {
        alert(err)
        console.log(err);
      });
  }



  const handleChange = async (event, identifier, index) => {
    if (index === undefined) {
      if (identifier === "salaryYear") {
        setSalaryYear(event.target.value)
        loaddata(event.target.value, salaryMonth);
      }
      if (identifier === "salaryMonth") {
        setSalaryMonth(event.target.value)
        loaddata(salaryYear, event.target.value);
      }
    }
    else {
        let element=slicedSalaryData[index];
        index=salaryData.indexOf(element);  
      let data = [...salaryData];
      if (identifier === "LeaveDays" && (event.target.value === null || event.target.value === undefined || event.target.value === "" || event.target.value < 0)) {
        data[index][identifier] = 0;
      }
      else if (identifier === "LeaveDays" && event.target.value > parseInt(data[index]["MonthDays"])) {
        data[index][identifier] = data[index]["MonthDays"];
      }

      else {
        data[index][identifier] = event.target.value;
      }


      if (parseInt(data[index]["LeaveDays"]) > parseInt(headerData["LeaveGraceDays"])) {
        let leaveday = parseInt(data[index]["LeaveDays"]) - parseInt(headerData["LeaveGraceDays"]);
        let hraAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["HRAPerc"])) / 100;
        let perDayHRAAmount = hraAmount / parseInt(data[index]["MonthDays"]);
        data[index]["HRAAmt"] = Math.round(hraAmount - (perDayHRAAmount * leaveday), 2);

        let ConvAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["ConvPerc"])) / 100;
        let perDayConvAmount = ConvAmount / parseInt(data[index]["MonthDays"]);
        data[index]["ConvAmt"] = Math.round(ConvAmount - (perDayConvAmount * leaveday), 2);

        let basicAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["DAPerc"])) / 100;
        let perDayBasicAmount = basicAmount / parseInt(data[index]["MonthDays"]);
        data[index]["BasicAmt"] = Math.round(basicAmount - (perDayBasicAmount * leaveday), 2);


        let CCAAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["CCAPerc"])) / 100;
        let perDayCCAAmount = CCAAmount / parseInt(data[index]["MonthDays"]);
        data[index]["CCAAmt"] = Math.round(CCAAmount - (perDayCCAAmount * leaveday), 2);
        debugger
        if (parseFloat(data[index]["BasicAmt"]) > 15001) {
          if (headerData["IsPfApplicable"] && data[index]["Ispf"]) {

            //let pfAmount=  (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["PFPerc"]))/100; 
            data[index]["PFAmt"] = 1800;
            data[index]["EPFAmt"] = 1800;
          }

          else {
            data[index]["PFAmt"] = 0;
            data[index]["EPFAmt"] = 0;
          }
        }
        else if (parseFloat(data[index]["BasicAmt"]) <= 15000) {
          if (headerData["IsPfApplicable"] && data[index]["Ispf"]) {

            let pfAmount = (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["PFPerc"])) / 100;
            data[index]["PFAmt"] = Math.round(pfAmount, 2);
            let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["EPFPerc"])) / 100;
            data[index]["EPFAmt"] = Math.round(EPFAmt, 2);
          }
          else {
            data[index]["PFAmt"] = 0;
            data[index]["EPFAmt"] = 0;
          }
        }

        if (parseFloat((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) < 21000) {
          let ESIAmt = (parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["ESIPerc"]) / 100;
          data[index]["ESIAmt"] = Math.round(ESIAmt, 2);


          //let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["EESIPerc"]) / 100;
          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2);

        }
        else {
          data[index]["ESIAmt"] = 0;

          data[index]["EESIAmt"] = 0;
        }
        if (parseFloat((data[index]["BasicAmt"])) > 15000) {
          let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["EPFPerc"])) / 100;

          data[index]["EPFAmt"] = 0;
          if (headerData["IsPfApplicable"] && data[index]["Ispf"]) {
            data[index]["EPFAmt"] = Math.round(EPFAmt, 2);
          }

          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2);
        }



        data[index]["TotalGrossSalAmt"] = parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"]);
        data[index]["LeaveDeductAmt"] =
          (
            (parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["OvertimeAmt"]))
            / (parseInt(data[index]["MonthDays"]))) * parseInt(data[index]["LeaveDays"]);

        data[index]["LeaveDeductAmt"] = 0
        if (data[index]["IsAbry"]) {
          data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])
        }
        else {
          data[index]["TotalDeductAmt"] = parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + + parseFloat(data[index]["PFAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"])

        }

        data[index]["NetSalary"] = parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["TotalDeductAmt"]);
      }
      else {
        let hraAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["HRAPerc"])) / 100;
        data[index]["HRAAmt"] = Math.round(hraAmount, 2);

        let ConvAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["ConvPerc"])) / 100;
        data[index]["ConvAmt"] = Math.round(ConvAmount, 2);
        let CCAAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["CCAPerc"])) / 100;
        let perDayCCAAmount = Math.round(CCAAmount, 2);
        data[index]["CCAAmt"] = Math.round(CCAAmount)// - (perDayCCAAmount * parseInt(data[index]["LeaveDays"])),2);

        let basicAmount = (parseFloat(data[index]["BasicSalary"]) * parseFloat(headerData["DAPerc"])) / 100;
        data[index]["BasicAmt"] = Math.round(basicAmount, 2);

        debugger
        if (parseFloat(data[index]["BasicAmt"]) > 15001) {
          if (headerData["IsPfApplicable"] && data[index]["Ispf"]) {

            //let pfAmount=  (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["PFPerc"]))/100; 
            data[index]["PFAmt"] = 1800;
            data[index]["EPFAmt"] = 1800;
          }

          else {

            data[index]["PFAmt"] = 0;
            data[index]["EPFAmt"] = 0;
          }
        }
        else if (parseFloat(data[index]["BasicAmt"]) <= 15000) {
          if (headerData["IsPfApplicable"] && data[index]["Ispf"]) {
            let pfAmount = (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["PFPerc"])) / 100;
            let EPFAmt = (parseFloat(data[index]["BasicAmt"]) * parseFloat(headerData["EPFPerc"])) / 100;
            data[index]["PFAmt"] = Math.round(pfAmount, 2);
            data[index]["EPFAmt"] = Math.round(EPFAmt, 2);
          }
        }
        if (parseFloat((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + parseFloat(data[index]["OvertimeAmt"]) + parseFloat(data[index]["CCAAmt"])) <= 21000) {
          let ESIAmt = (parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["ESIPerc"]) / 100;
          data[index]["ESIAmt"] = Math.round(ESIAmt, 2);
          let EESIAmt = ((data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["CCAAmt"])) * parseFloat(headerData["EESIPerc"]) / 100;
          data[index]["EESIAmt"] = Math.round(EESIAmt, 2);
        }
        else {
          data[index]["ESIAmt"] = 0;
          data[index]["EESIAmt"] = 0;
        }




        data[index]["TotalGrossSalAmt"] = Math.round(parseFloat(data[index]["BasicAmt"]) + parseFloat(data[index]["HRAAmt"]) + parseFloat(data[index]["ConvAmt"]) + +parseFloat(data[index]["CCAAmt"]) + parseFloat(data[index]["OvertimeAmt"]), 2);
        data[index]["LeaveDeductAmt"] = 0
        if (data[index]["IsAbry"]) {
          data[index]["TotalDeductAmt"] = Math.round(parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"]), 2)
        }
        else {
          data[index]["TotalDeductAmt"] = Math.round(parseFloat(data[index]["ProfTaxAmt"]) + parseFloat(data[index]["TdsAmt"]) + parseFloat(data[index]["PFAmt"]) + parseFloat(data[index]["ESIAmt"]) + parseFloat(data[index]["AdvAmt"]) + parseFloat(data[index]["LeaveDeductAmt"]), 2)


        }

        data[index]["NetSalary"] = Math.round(parseFloat(data[index]["TotalGrossSalAmt"]) - parseFloat(data[index]["TotalDeductAmt"]), 2);

      }

      setSalaryData(data);

    }

  }
  
  const filterGrid = async (event) => {
    
    setSerachText(event.target.value);
    if(event.target.value && event.target.value.length>0)
    {
      let data=[];
      {salaryData.filter(item => item.EmployeeName.toLowerCase().includes(event.target.value.toLowerCase())).map(filteredName => (
  
        data.push(filteredName)
      ))}
  
      setSlicedSalaryData([...data])
    }
    else
    {
      let data = [];
        for (let index = 0; index < salaryData.length; index++) {
          if (index < (pageSize * 1)) {
            const element = salaryData[index];
            data.push(element);
          }
          setSlicedSalaryData([...data]);
          setCurrentPage(1);



        }
    }
    
    
  }
  const goto = async (direction) => {
    debugger
    if (direction === "P") {
      let curr_page = currentPage;
      let curr_page_size = pageSize;
      let total_page = totalPage;
      setSlicedSalaryData([]);
      let data = [];
      let j = pageSize;
      let toRow = pageSize * (currentPage - 1);
      if ((currentPage - 1) <= 1) {
        j = 0;
      }
      else {
        j = pageSize * ((currentPage - 2))
      }


      let i = 0;
      for (i = j; i < salaryData.length; i++) {
        if (i < toRow) {
          const element = salaryData[i];
          data.push(element);
        }
        setSlicedSalaryData(data);
        setCurrentPage(currentPage - 1)
      }

    }
    else {
      let curr_page = currentPage;
      let curr_page_size = pageSize;
      let total_page = totalPage;
      setSlicedSalaryData([]);
      let data = [];
      let i = 0;
      for (i = (pageSize * currentPage); i < salaryData.length; i++) {
        if (i < (pageSize * (currentPage + 1))) {
          const element = salaryData[i];
          data.push(element);
        }
        setSlicedSalaryData(data);
        setCurrentPage(currentPage + 1)
      }
    }

  }
  const handleSubmit = async (formdata) => {
    debugger
    //     console.log(formdata);
    let user = localStorage.getItem("userId");
    if (user) {
      user = parseInt(user);
    }
    if (salaryYear === '') {
      notify("Please Select Salary Year", "warning")
      return;
    }
    if (salaryMonth === '') {
      notify("Please Select Salary Year", "warning")
      return;
    }
    if (salaryData.length === 0) {
      notify("Please load Salary ", "warning")
      return;
    }
    let data = {};
    data.headerData = [{ id: id, monthnumber: salaryMonth, year: salaryYear, "createdby": user, "createddate": "2021-07-16", "modifiedby": 1, "modifieddate": "2021-07-16" }]
    data.salaryData = [...salaryData];

    apiClient.createupdatesalary(data).then(res => {
      if (res[0].result) {
        notify(res[0].message)
        redirect("/employee-sal");
      }
      else {
        notify(res[0].message)
      }


    }).catch(err => {
      notify("unable to add Invoice", "warning")
      console.log(err);
    });
  }

  return (
    <Create title="Employee Salary Create " {...props}>
      <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} onSave={handleSubmit} />} onSubmit={handleSubmit} >
        <div style={{ fontSize: "20px" }} >Create Employee Salary<br /></div>
        <SelectInput source="salaryYear" label="Salary Year" validate={[required()]} fullWidth={true} formClassName={classes.one_4_input} choices={[{ id: '2020', name: '2020' }, { id: '2021', name: '2021' }, { id: '2022', name: '2022' }, { id: '2023', name: '2023' }, { id: '2024', name: '2024' }, { id: '2025', name: '2025' }, { id: '2026', name: '2026' }, { id: '2027', name: '2027' }, { id: '2028', name: '2028' }, { id: '2029', name: '2029' }, { id: '2030', name: '2030' }]} onChange={(event) => handleChange(event, "salaryYear")} />
        <SelectInput source="salaryMonth" label="Salary Month" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input} choices={[{ id: '1', name: 'Jan' }, { id: '2', name: 'Feb' }, { id: '3', name: 'March' }, { id: '4', name: 'April' }, { id: '5', name: 'May' }, { id: '6', name: 'June' }, { id: '7', name: 'July' }, { id: '8', name: 'August' }, { id: '9', name: 'Sept' }, { id: '10', name: 'Oct' }, { id: '11', name: 'Nov' }, { id: '12', name: 'Dec' }]} onChange={(event) => handleChange(event, "salaryMonth")} />
        <div style={{ fontSize: "19px", marginTop: "29px" }} >Salary Detail<br /></div>
        {salaryData.length > 0 && <div style={{ fontSize: "19px", marginTop: "29px",width: "100%" }} >Search Employee 
        <input type="text" value={serachText} onChange={(event)=>filterGrid(event)}  style={{ width: "400px", textAlign: "left" }} ></input></div>
}
        {salaryData && salaryData.length > 0 && <div style={{ width: "100%", overflow: "scroll" }}>
          <Table className={classes.customtable1} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell >SNO</TableCell>
                <TableCell >Employee </TableCell>
                <TableCell>Month Days</TableCell>
                <TableCell>Working Days</TableCell>
                <TableCell>Present Days</TableCell>
                <TableCell>Leave Days</TableCell>
                <TableCell>Sal Calc Days </TableCell>
                <TableCell>Basic (+) </TableCell>
                <TableCell>HRA (+) </TableCell>
                <TableCell>Conveyance (+) </TableCell>
                <TableCell>CCA (+) </TableCell>
                <TableCell>Overtime Amt (+) </TableCell>
                <TableCell>Gross Amt </TableCell>
                <TableCell>Proff. Tax Amt(-) </TableCell>
                <TableCell>PF Amt(-) </TableCell>
                <TableCell>ESI Amt(-)</TableCell>
                <TableCell>Adv. Amt(-)</TableCell>
                <TableCell>Leave Ded Amt </TableCell>
                <TableCell>TDS Amt </TableCell>
                <TableCell>Tot. Ded.</TableCell>
                <TableCell>Tot. Pay Amt.</TableCell>
                <TableCell>EEPF Amt.</TableCell>
                <TableCell>EESI AMT.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {slicedSalaryData.map((item, index) => {
                return <tr>
                  <TableCell >{index + 1} </TableCell>
                  <TableCell >{item.EmployeeName} </TableCell>
                  <TableCell>{item.MonthDays}</TableCell>
                  <TableCell>{item.WorkingDays}</TableCell>
                  <TableCell>{item.PresentDays}</TableCell>
                  <TableCell><input type="number" style={{ width: "63px", textAlign: "right" }} value={item.LeaveDays} onChange={(event) => handleChange(event, "LeaveDays", index)} ></input></TableCell>
                  <TableCell>{parseInt(item.LeaveDays) > parseInt(headerData["LeaveGraceDays"]) ? parseInt(item.MonthDays) - (parseInt(item.LeaveDays) - parseInt(headerData["LeaveGraceDays"])) : parseInt(item.MonthDays)}</TableCell>
                  <TableCell>{parseFloat(item.BasicAmt).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.HRAAmt).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.ConvAmt).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.CCAAmt).toFixed(2)} </TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "OvertimeAmt", index)} value={parseFloat(item.OvertimeAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.TotalGrossSalAmt).toFixed(2)} </TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "ProfTaxAmt", index)} value={parseFloat(item.ProfTaxAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.PFAmt).toFixed(2)} </TableCell>
                  <TableCell>{parseFloat(item.ESIAmt).toFixed(2)}</TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "AdvAmt", index)} value={parseFloat(item.AdvAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.LeaveDeductAmt).toFixed(2)}</TableCell>
                  <TableCell><input type="number" style={{ width: "150px", textAlign: "right" }} onChange={(event) => handleChange(event, "TdsAmt", index)} value={parseFloat(item.TdsAmt)}></input>  </TableCell>
                  <TableCell>{parseFloat(item.TotalDeductAmt).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.NetSalary).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.EPFAmt).toFixed(2)}</TableCell>
                  <TableCell>{parseFloat(item.EESIAmt).toFixed(2)}</TableCell>
                </tr>
              })}
            </TableBody>
          </Table>


        </div>
        }
        {serachText.toString().trim().length==0 && slicedSalaryData.length > 0 && <div style={{ textAlign: "center", minWidth: "100%",marginBottom:"50px" }}>
          <div style={{display:"flex" ,height:"30px",width:"100%",padding:"2px" }}  >
          <div style={{width:"200px"}} >
              
              <h6> Page Size: {pageSize}</h6>
              
                </div>
            <div style={{width:"200px"}} >
              
            <h6>Current Page: {currentPage}</h6>
              </div>
              <div style={{width:"200px"}} >
              <h6>Total Pages:{totalPage}</h6> 
            </div>
            <div style={{marginLeft:40 ,marginTop:"10px" }}  >
              <button type="button" onClick={() => goto("P")} disabled={currentPage === 1}>Previous Page</button>
              </div>
              <div style={{marginLeft:40 ,marginTop:"10px" }}  >
              <button type="button" onClick={() => goto("N")} disabled={(currentPage === totalPage || 0 === totalPage)} >Next Page</button>
            </div>
          </div>

        </div>
        }

        {salaryData && salaryData.length > 0 &&

          <div className="salarySummary">
            <div className="salaryContainer">
              <div className="salaryLabel">
                Total Gross:
              </div>
              <div className="salaryValue">
                {parseFloat((salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.TotalGrossSalAmt); }, 0))).toFixed(2)}

              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                Total Deduction :
              </div>
              <div className="salaryValue">

                {parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.TotalDeductAmt); }, 0)).toFixed(2)}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                Net Salary:
              </div>
              <div className="salaryValue">
                {headerData["TotalPay"]}
                {parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.NetSalary); }, 0)).toFixed(2)}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                Emp Contribution :
              </div>
              <div className="salaryValue">

                {parseFloat(salaryData.reduce(function (cnt, o) { return cnt + parseFloat(o.EESIAmt); }, 0)).toFixed(2)}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                DA %:
              </div>
              <div className="salaryValue">
                {headerData["DAPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                Conv. %:
              </div>
              <div className="salaryValue">
                {headerData["ConvPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                CCA. %:
              </div>
              <div className="salaryValue">
                {headerData["CCAPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                PF %:
              </div>
              <div className="salaryValue">
                {headerData["PFPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                ESI %:
              </div>
              <div className="salaryValue">
                {headerData["ESIPerc"]}
              </div>
            </div>


            <div className="salaryContainer">
              <div className="salaryLabel">
                HRA % :
              </div>
              <div className="salaryValue">
                {headerData["HRAPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                EPF % :
              </div>
              <div className="salaryValue">
                {headerData["EpfPerc"]}
              </div>
            </div>
            <div className="salaryContainer">
              <div className="salaryLabel">
                EESI % :
              </div>
              <div className="salaryValue">
                {headerData["EEsiPerc"]}
              </div>
            </div>
          </div>


        }


      </SimpleForm>

    </Create>
  );
};

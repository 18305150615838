import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer, Document, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import React from 'react'
import sign from '../../../images/hd-signature.jpg'
import sign1 from '../../../images/hd-signature1.jpg'
const Signature = (props) => {
    let styles = props.styles
    return <View style={styles.signatureBox} >
        <Text style={{fontFamily: 'Times-Italic',fontWeight: "bold"}} >{props.data.InvoiceFooterText}</Text>
        <Text style={{ fontWeight: "bold", textAlign: "right",paddingRight:10 }}>{props.data.AccountHolderName}</Text>
        <View style={{ height: "50%",width:"100%",textAlign:"right",flexDirection:"row" }}  >
        <View style={{ width:"80%",textAlign:"right" }} />  
        <View style={{ width:"20%",textAlign:"right" ,}}  >
        <Image style={{ height: 60,width:100}}  src={props.data.SigUrl.trim() == "https://hdfiles.blob.core.windows.net/hospitals/hd-signature.jpg"?sign:sign1}/>    
         </View> 
        </View>
        
        <Text style={{ fontWeight: "bold", textAlign: "right",paddingRight:10,paddingTop:5 }}>{props.data.AuthSignatory}</Text>
    </View>
}
export default Signature

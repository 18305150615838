import React, {useState, useCallback,useEffect } from 'react';
import {PDFViewer,Image, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import {Table,TableHeader,TableCell,TableBody,DataTableCell} from '@david.kucsai/react-pdf-table'
import { BorderLeft } from '@material-ui/icons';
const styles = StyleSheet.create({
  page: {
//    paddingTop: 120,
paddingTop: 10,
    paddingBottom: 50,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,
    
  },
  headerContainer: {
    marginTop: 10,
    textAlign: 'center',
    flexDirection: "row",
    fontSize:14
  },
  FooterContainer: {
    marginTop: 10,
    textAlign: 'left',
    flexDirection: "row",
    fontSize:9
  },
  FooterContainer1: {
    marginTop: 40,
    
    textAlign: 'left',
    flexDirection: "row",
    fontSize:9
  },
  FooterContainer2: {
    marginTop: 10,
    
    textAlign: 'left',
    flexDirection: "row",
    fontSize:9
  },
  headerRow: {
    marginTop: 8,
    flexDirection: "row",
    
  },
  
  headerTable: {
    fontSize:12,
    height:40,
    textAlign: 'center',
    fontWeight:"bold",
    border:"none"
    
  },
  
  detailTable:{
    marginTop:50
  },
  
  tableHeader: {
    fontSize:11,
    height:20,
    textAlign: 'center',
    fontWeight:"bold",
    marginLeft:10
  },
  tableHeader1:
  {
    fontSize:11,
    height:20,
    textAlign: 'center',
    fontWeight:"bold",
    width:100

    
  },
  tabletextRow: {
    fontSize:9,
    height:14,
    textAlign: 'left',
    marginLeft:10
  },
  tablenumberRow: {
    fontSize:9,
    height:25,
    textAlign: 'right',
    marginRight:10
  },
  FooterImage:{
    marginTop:20
  }

});
 

export const RealizationPrint = props => {
   
   const [isLoading, setLoading] = useState(true);
   const [headerData, setHeaderData] = useState([]);
   const [detailData, setDetailData] = useState([]);
   const [footerDetail, setFooterDetail] = useState("");
   const [footerDetail1, setFooterDetail1] = useState("");
   const [headerImage, setHeaderImage] = useState("");
   const [footerImage, setFooterImage] = useState("");
   const LoadData= (id) => {
        apiClient.realizationOrderPrint (id).then(res => {
          let data=[];
          if(res.headerData)
          {
            data.push({display:"Tpa Name :",value:res.headerData[0].TpaName})
            data.push({display:"Insurer Name :",value:res.headerData[0].InsName})
            data.push({display:"Paitent Name :",value:res.headerData[0].PName})
            data.push({display:"DOA :",value:res.headerData[0].DOA})
            data.push({display:"DOD :",value:res.headerData[0].DOD})
            data.push({display:"Hosp Bill No/ Regn No :",value:res.headerData[0].HBillNo})
            data.push({display:"CCN NO :",value:res.headerData[0].CCNNO})
            data.push({display:"Card No :",value:res.headerData[0].CardNo})
            data.push({display:"Invoice No :",value:res.headerData[0].InvoiceNo})
            data.push({display:"Total Bill Amount :",value:res.headerData[0].TotalBillAmount })
            data.push({display:"Discount :",value:res.headerData[0].Discount })
            data.push({display:"Patient Paid Amount :",value:res.headerData[0].PatientPaidAmt })
            data.push({display:"Claim Amount :",value:res.headerData[0].ClaimAmt  })
            data.push({display:"Total No of Pages :",value:res.headerData[0].TotalPage  })
            data.push({display:"Date of Dispatch :",value:res.headerData[0].DateOfDispatch   })
            
            setFooterDetail(res.headerData[0].FooterDetails )
            setFooterDetail1(res.headerData[0].FooterDetails1 )
            setHeaderImage(res.headerData[0].HeaderUrl)
            setFooterImage(res.headerData[0].FooterUrl)
          }
          setHeaderData(data);
          setDetailData(res.detailData);
          setLoading(false);
        
        }).catch(err => {
          setLoading(false);
          console.log(err);
        });

    }
    useEffect(() => {LoadData(props.realizOrdId);},[]);
     
    return (

      
      <React.Fragment> 
        <div>
          <div style={{float:"right"}}  onClick={props.onClose}><CancelSharpIcon/></div>
        </div>
        {isLoading===false?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
              <Page  size="A4" style={styles.page}>
              <Image  style={{flex: 0.2}}  src={headerImage}/>    
                <View style={{flex: 1}} >
                
                  <Table  data={[]} >
                    <TableHeader>
                        <TableCell style={styles.tableHeader}> TPA Dispatch Details   </TableCell>                         
                    </TableHeader>
                </Table>                  

                  {headerData && headerData.length>0? 
                  <Table  data={headerData} >
                    
                    <TableBody>
                        <DataTableCell style={styles.tabletextRow} getContent={(r) => r.display}/>
                        <DataTableCell style={styles.tabletextRow} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>                  
                :null}

                  
                {detailData && detailData.length>0? 
                  <Table  data={detailData} >
                    <TableHeader>
                        <TableCell style={styles.tableHeader} weighting={1} > Document Type </TableCell>                         
                        <TableCell style={styles.tableHeader} weighting={0.2}> No of Pages </TableCell>
                    </TableHeader>
                     <TableBody>
                        <DataTableCell style={styles.tabletextRow} weighting={1} getContent={(r) => r.DocumentType}/>
                        <DataTableCell style={styles.tabletextRow} weighting={0.2} getContent={(r) => r.DocumentValue}/>
                    </TableBody>
                </Table>                  
                :null}
                <View style={styles.FooterContainer}>
                  <Text>{footerDetail}</Text>
                </View>
                <View style={styles.FooterContainer}>
                  <Text>{footerDetail1}</Text>
                </View>
                <View style={styles.FooterContainer}>
                  <Text>Your Faithfully</Text>
                </View>
                <View style={styles.FooterContainer}>
                  <Text>Authorized Signatory</Text>
                </View>
                
              </View>
              <View style={styles.FooterImage}>
                <Image  src={footerImage}/>    
                </View>
              </Page>
          </Document>
         </PDFViewer>:null}
        </React.Fragment>
    );
}

import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import {DisplayDate} from "../../common/DisplayDate";

const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <TextInput variant="outlined" label="Record No" source="billNo~like" alwaysOn />
    <TextInput variant="outlined" label="Name" source="pName~like" alwaysOn />
    <TextInput variant="outlined" label="Mobile Number" source="pMobNo~like" />
    <TextInput variant="outlined" label="Hospital Bill no" source="pEmail~like" />
  </Filter>
);
export const HospRealizeOrderList = (props) => {
  return (
    <List bulkActionButtons={false} exporter={false} className="grid-list" classes={{ actions: "my-action", main: "main", content:"content", }}
    {...props} title="Realize Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
      <Datagrid rowClick="edit">
        <TextField source="billNo" label="Record No"/>
        <TextField source="pName" label="Name" />
        <TextField source="pMobNo" label="Mobile Number"/>
        <TextField source="pEmail" label="Email address"/>
        <DisplayDate source="date"  label="Entry Date"/>
        <TextField source="hBillNo"  label="Hospital Bill no."/>
        <ReferenceField  label="Hospital" source="hospitalId" reference="hospitals" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <DisplayDate source="doa"  label="DOA" locales="fr-FR" />
        <DisplayDate source="dod"  label="DOD" locales="fr-FR" />
        <TextField source="ccnno"  label="CCNNo"/>
        <DisplayDate source="frecDate" label="Received At HDC" locales="fr-FR" />
        <DisplayDate source="fSubDate" label="File Submission Date" locales="fr-FR" />
        <ReferenceField label="TPA" source="tpaId" reference="tpas" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Corporate" source="corporateId" reference="corporates" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="cardNo" label="Card No"/>
        <ReferenceField source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="hBillAmt" label="Hospital bill number"/>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};

import CircularProgress from "@material-ui/core/CircularProgress"; 
import {PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {Table,TableHeader,TableCell,TableBody,DataTableCell} from '@david.kucsai/react-pdf-table'
import React from 'react'
const GrandTotal = (props) => {
    let styles=props.styles
return <View style={styles.tableHeaderStyle}>
                  <View style={styles.tableHeaderStylebox1}>
                    <Text> </Text>
                  </View>
                  <View style={styles.tableHeaderStylebox2GrandTotal}>
                    <Text>Total (Rs.)</Text>
                  </View>
                  <View style={styles.tableHeaderStylebox3GrandTotal}>
                    <Text>{props.amount?props.amount.toFixed(2):"0.00"}</Text>
                  </View>

                </View>
}
export default GrandTotal
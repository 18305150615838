import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const TPAConfigCreate = props => {
    const classes = useStyles({});
    
    const handleSubmit = async (formdata) => {
         console.log(formdata);
        
        let data = {
            hospitalId: parseInt(props.hospitalId),
            tpaid: formdata.tpaid,
            isTieUp: formdata.isTieUp,
            empanelled: formdata.empanelled,
            agrmtFDt: formdata.agrmtFDt,
            agrmtTDt: formdata.agrmtTDt,
            remarks: formdata.remarks,
            createdBy:0,
            
        }
        console.log(data);
        apiClient.createHospitalTpaConfig(data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">

                <ReferenceInput  perPage={2000} source="tpaid" label="TPA" reference="tpas" fullWidth={true} sort={{ field: 'name', order: 'ASC' }} >
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput>
                <SelectInput source="empanelled" label="Empanelled" validate={required()}  fullWidth={true}  choices={[ { id: 'Fresh', name: 'Fresh' }, { id: 'Renewal ', name: 'Renewal' } ]} />
            
                {/* <TextInput  validate={required()} fullWidth={true} source="empanelled" label="Empanelled" /> */}
                <SelectInput fullWidth={true} source="isTieUp" label="Tie Up" choices={[ { id: 'IN PROCESS', name: 'IN PROCESS' }, { id: 'YES', name: 'YES' }, ]}/>
                <DateInput  validate={required()} fullWidth={true} source="agrmtFDt" label="Argmt Date From" />
                <DateInput  validate={required()} fullWidth={true} source="agrmtTDt" label="Argmt Date To" />
                <TextInput  validate={required()} fullWidth={true} source="remarks" label="Remarks" />
                 

                 

            </SimpleForm>
        </Edit>
    );
}
export default TPAConfigCreate;
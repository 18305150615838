
import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import * as apiClient from "../../common/apiClient";
export const DispatchFields = ({ formData, ...rest }) => {
    const form = useForm();
    const loadRates= async(e)=>{
debugger
        await apiClient.getRate(formData.courierId,formData.courierDestinationId,e.target.value).then((response)=>{ 
            
            if(response && response .length>0)
            form.change("courierRate",response[0].rateInKg)
            form.change("deliveryamt",response[0].rateInKg)
            
          })
    }
    const classes = useStyles({});
    return (
        <>
            {rest.hasOtherDocEdit &&
                <div style={{ width: "100%" }}>

                    <DateInput variant="outlined" label="Dispatched/Delivery date" source="deliveryDate" formClassName={classes.one_4_input} initialValue="" />
                    {"   "}
                    <SelectInput variant="outlined" source="deliveryType" label="Courier/Hand delivery" choices={[{ id: 'NA', name: 'NA' }, { id: 'Courier', name: 'Courier' }, { id: 'Handdelivery', name: 'Hand delivery' },]} formClassName={classes.two_4_input} initialValue="NA" />
                    {"   "}
                    {
                        formData.deliveryType === "Handdelivery" ?
                            <TextInput variant="outlined" validate={[required()]} source="courierName" label=" Courier name" formClassName={classes.three_4_input} initialValue="" />
                            : null
                    }

                    {
                        formData.deliveryType === "Courier" ?
                            <ReferenceInput
                                perPage={500} sort={{ field: 'name', order: 'ASC' }}
                                label="Courier" variant="outlined" source="courierId" reference="lookups" formClassName={classes.three_4_input}
                                filter={{ type: 'COUR	' }}  >
                                <SelectInput variant="outlined" optionText="name" disabled={rest.hasDataEntryEdit} />
                            </ReferenceInput>

                            : null
                    }
{"   "}
{
                        formData.deliveryType === "Courier" ?
                            <ReferenceInput
                                perPage={500} sort={{ field: 'courierDestination', order: 'ASC' }}
                                label="Courier Destinations" variant="outlined" source="courierDestinationId" reference="courier-destinations" formClassName={classes.last_4_input}
                                filter={{ courierId: formData.courierId?formData.courierId:0 }}  >
                                <SelectInput variant="outlined" optionText="courierDestination" disabled={rest.hasDataEntryEdit} />
                            </ReferenceInput>

                            : null
                    }


{"   "}
{
                        formData.deliveryType === "Courier" ?
                            <ReferenceInput
                                perPage={500} sort={{ field: 'courierWtSlab', order: 'ASC' }}
                                label="Courier Wt Slab" variant="outlined" source="courierWtSlabId" reference="courier-wt-slabs" formClassName={classes.last_4_input}
                                filter={{ courierId: formData.courierId?formData.courierId:0 }} 
                                onChange={(e)=>{loadRates(e)}}
                                >
                                <SelectInput variant="outlined" optionText="courierWtSlab" disabled={rest.hasDataEntryEdit} />
                            </ReferenceInput>

                            : null
                    }
 

                    {"   "}
                    {formData.deliveryType === "Courier" || formData.deliveryType === "Handdelivery" ?
                        <NumberInput variant="outlined" validate={[required()]} source="weightInKg" label=" Weight In Kg" formClassName={classes.last_4_input} initialValue="" />
                        : null
                    }
                    {"   "}
                    {
                        formData.deliveryType === "Courier" || formData.deliveryType === "Handdelivery" ?
                            <NumberInput variant="outlined" validate={[required()]} source="deliveryamt" label=" Delivery amt"
                                disabled={formData.deliveryType === "Courier" || rest.hasDataEntryEdit}
                                formClassName={classes.two_4_input} initialValue="0" />
                            : null
                    }
                    {"   "}
                    {formData.deliveryType === "Courier" || formData.deliveryType === "Handdelivery" ?
                        <TextInput variant="outlined" validate={[required()]} source="podNo" label=" Pod No" formClassName={classes.one_4_input} initialValue="" />
                        : null
                    }
                    
                    {"   "}
                    {formData.deliveryType === "Courier" || formData.deliveryType === "Handdelivery" ?
                        <TextInput variant="outlined" validate={[required()]} source="toLocation" label=" To Location" formClassName={classes.thee_4_input} initialValue="" />
                        : null
                    }


                </div>}
        </>

    )
}
export default DispatchFields;
import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  required,
  useNotify,
  useRedirect,
  BooleanInput,
  FormDataConsumer

} from "react-admin";
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import Fields from "./Fields";

export const CustomEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (formdata) => {
    let userId: any
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    formdata.modifyBy = userId;
    formdata.modifyDate = new Date(); 
     
    if (new Date(formdata.approvedToDate) > new Date(formdata.attedanceDate)) {
      notify("Approved to date cannot be more than applied to date", "warning")
      return;
    }
    
    apiClient.updateattendanceregularizes(formdata).then(res => {
      notify("Attendance Regularization is updated")
      redirect("/employee-attendance-regularizes-approve");
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <Edit title="Attendance Regularization Edit" {...props}>
      <SimpleForm onSubmit={handleSubmit} redirect="list" toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
 
        <DateInput variant="outlined" label="Attendance Date" source="attendanceDate" fullWidth={true} validate={[required()]} disabled/>
        <ReferenceInput label="Type"  fullWidth={true}  disabled
                    perPage={5000} source="attendanceTypeId"
                    filter={{ type: 'RegLt' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"   />
                </ReferenceInput>
        <TextInput source="location" label='Location' fullWidth={true} disabled/>   
        <TextInput source="remarks" label='Remarks' fullWidth={true} disabled/>   
        <DateInput variant="outlined" label="Approved  Date" source="approvedDate" fullWidth={true} validate={[required()]} />
        <TextInput source="approverRemarks" label='Approver Remarks' fullWidth={true} />   
        {/* <BooleanInput source="isApproved" label="Is Approved"/>
        <BooleanInput source="isReject" label="Is Reject"/> */}
        <FormDataConsumer>
              {formDataProps => 
             <Fields {...formDataProps}  {...props} />
              }
            </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';



const PostCreateToolbar = props => (

    <Toolbar {...props}>
        <SaveButton submitOnEnter={true} onSave={props.onSave} />
        <Button
            variant="contained" color="primary"
            label="Back" size="medium"
            startIcon={<CancelIcon />}
            style={{ marginLeft: "30px" }}
            onClick={props.onCancel}
        />
    </Toolbar>
);
const RoleStations = props => {
    let userID = localStorage.getItem("userId");
    let defaultValues = {
        createdDate: new Date(),
        createdBy: userID,
        roleTypeId: parseInt(props.roleTypeId) ,
        isActive: true
    };

    const handleSubmit = async (formdata) => {
        console.log(formdata);

        apiClient.addEntiyRecord("role-station-maps", formdata).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    const filterToQuery = (searchText) => ({
        description: { like: searchText, options: 'i' },
      });
    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit} initialValues={defaultValues}
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel} />} variant="outlined">

                <ReferenceInput  perPage={50} label=" Stations " source="stationId" reference="stations" fullWidth={true} validate={[required()]}
                    sort={{ field: 'name', order: 'asc' }}>
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                    filterToQuery={filterToQuery}
                    emptyValue={''}
                    />
                </ReferenceInput>
               

            </SimpleForm>
        </Create>
    );
}
export default RoleStations;
import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  required,
  useNotify,
  useRedirect,
  BooleanInput

} from "react-admin";
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeLeaveEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (formdata) => {
    let userId: any
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    formdata.modifyBy = userId;
    formdata.modifyDate = new Date(); 
    if (new Date(formdata.approvedFromDate) > new Date(formdata.approvedToDate)) {
      notify("Approved To date cannot be less than approved from date", "warning")
      return;
    }
    if (new Date(formdata.approvedFromDate) < new Date(formdata.fromDate)) {
      notify("Approved From date cannot be less than applied from date", "warning")
      return;
    }
    if (new Date(formdata.approvedToDate) > new Date(formdata.toDate)) {
      notify("Approved to date cannot be more than applied to date", "warning")
      return;
    }
    apiClient.updateleave(formdata).then(res => {
      notify("Leave is updated")
      redirect("/employee-leaves-approve");
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <Edit title="Leave Edit" {...props}>
      <SimpleForm onSubmit={handleSubmit} redirect="list" toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
        <ReferenceInput label="Leave Type" source="leaveTypeId" reference="leave-types" fullWidth={true} validate={[required()]} disabled >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput variant="outlined" label="From Date" source="fromDate" fullWidth={true} validate={[required()]} disabled/>
        <DateInput variant="outlined" label="To Date" source="toDate" fullWidth={true} validate={[required()]} disabled/>
        <NumberInput variant="outlined" label="Total Days" source="totalDays" fullWidth={true} validate={[required()]} disabled />
        <TextInput source="remarks" label='Remarks' fullWidth={true} disabled/>   
        <DateInput variant="outlined" label="Approved From Date" source="approvedFromDate" fullWidth={true} validate={[required()]} />
        <DateInput variant="outlined" label="Approved To Date" source="approvedToDate" fullWidth={true} validate={[required()]} />
        <NumberInput variant="outlined" label="Total Approved Days" source="totalApprovedDays" fullWidth={true} validate={[required()]}  />
        <TextInput source="approverRemarks" label='Approver Remarks' fullWidth={true}/>   
        <BooleanInput source="isApproved" label="Is Approved"/>
      </SimpleForm>
    </Edit>
  );
};

import React, { useState, useCallback } from 'react';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import RealizationOtherDocumentsCreate from "./RealizationOtherDocumentsCreate";
import RealizationDocumentsCreate from "./RealizationDocumentsCreate";
import * as apiClient from "../../common/apiClient";
import RealizationDocumentEdit from "./RealizationDocumentEdit";
import { RealizationOrderPatmentFields } from "./RealizationOrderPatmentFields";
import { RealizationOrderOtherFields } from "./RealizationOrderOtherFields";

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 750,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}

const UploadDocument = (props) => {
  let { record } = props;
  return (
    <div className="upload-document" onClick={() => {
      props.onClick(record);
    }}>Upload Document</div>
  )
}


export const HospRealizeOrderEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddOtherDocuments, setIsAddOtherDocuments] = useState(false);

  const addFromSetup = (id) => {
    
  }
  const onCloseDocumentForm = () => {
    setIsAddDocuments(false);
    setIsEditDocumentRecord(0);
    setIsEditDocuments(false);
    refresh();
  }
  const onCloseOtherDocumentForm = () => {
    setIsAddOtherDocuments(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
  }
  const handleClose = useCallback(() => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
  }, []);
  const redirectMe = (basePath, id, data) => {
    console.log(basePath);
    console.log(id);
    console.log(data);
  };
  return (
    <React.Fragment>
      <Edit title="Realize Order Details"
       
        {...props} undoable={false}>
        <TabbedForm redirect={false} variant="outlined" toolbar={<FormToolbar {...props} hideSave={true} showDelete={false} />}>
          <FormTab label="Basic Details">
            <TextInput validate={[required(), minLength(3)]} source="billNo" label="Record No" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} source="hBillNo" label="Hospital Bill No" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.first_inline_input} />
            <ReferenceInput disabled={true} perPage={500} sort={{ field: 'name', order: 'ASC' }}
              validate={[required()]} label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <DateInput validate={[required()]} label="DOA" source="doa" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput validate={[required()]} label="DOD" source="dod" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} label="Card No" source="cardNo" fullWidth={true} formClassName={classes.first_inline_input} />
            <TextInput validate={[required(), minLength(3)]} label="CCN No" source="ccnno" fullWidth={true} formClassName={classes.last_inline_input} />

            <TextInput validate={[required(), minLength(3)]} source="policyNo" fullWidth={true} formClassName={classes.first_inline_input} />
            <ReferenceInput disabled={true}
              perPage={50} sort={{ field: 'name', order: 'ASC' }}
              validate={[required()]} label="TPA Name" source="tpaId" reference="tpas" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput disabled={true}
              perPage={50} sort={{ field: 'name', order: 'ASC' }}
              validate={[required()]} label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.first_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput disabled={true}
              perPage={50} sort={{ field: 'name', order: 'ASC' }}
              label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
              <SelectInput optionText="name" />
            </ReferenceInput>

            <DateInput validate={[required()]} label="Received At HDC" source="frecDate" fullWidth={true} formClassName={classes.first_inline_input} />
            <DateInput label="File Submission Date" source="fSubDate" fullWidth={true} formClassName={classes.last_inline_input} />

            <ReferenceInput disabled={true}
              perPage={50} sort={{ field: 'name', order: 'ASC' }}
              label="Status" source="statusId" reference="lookups" formClassName={classes.first_inline_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </FormTab>

          <FormTab label="Billing Info.">

            <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderPatmentFields {...formDataProps} />
              )}
            </FormDataConsumer>

          </FormTab>
        </TabbedForm>
      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isaddDocuments || isaddOtherDocuments || isEditDocuments} >

        {isaddOtherDocuments && <RealizationOtherDocumentsCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isaddDocuments && <RealizationDocumentsCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isEditDocuments && <RealizationDocumentEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          record={isEditDocumentRecord}
          {...props} />}
      </Drawer>
    </React.Fragment>
  );
};

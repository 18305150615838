import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'


import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  
  AutocompleteInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import InvoicePrint2 from "./InvoicePrint2/InvoicePrint";
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      marginRight: 5,
      
      width: 650,
  },
}));
const Filters = props => (
  <Filter {...props} variant="outlined">
  <SearchInput variant="outlined" label="Code" source="code~like" alwaysOn /> 
  
</Filter>
);
export const SalaryList = (props) => {
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintWithOutHeader, setIsPrintWithOutHeader] = useState(false);
  const [invoiceId, setinvoiceId] = useState(0);
  
  
  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrintWithOutHeader(false);
  }
   
const PrintButton=(props)=>( 
  <Button onClick={props.click}  > Print With Header </Button>
) 
const PrintButtonWithoutHeader=(props)=>( 
  <Button onClick={props.click}  > Print  </Button>
) 
  const onCancel = () => {
    setIsPrint(false)
  }
  const handleClose = useCallback(() => {
    setIsPrint(false);
}, []);
function showPopup(id){
    
  setinvoiceId(id)
  setIsPrint(true);
  setIsPrintWithOutHeader(false);
}
function showPopupWithoutHeader(id){ 
  setinvoiceId(id)
  setIsPrint(true);
  setIsPrintWithOutHeader(true);
}

const DisplayFileLink = (props) => {
  let { record } = props;

  return (
    <PrintButton click={(e)=>showPopup(record.id)}   />
    
  )
}
const DisplayFileLink1 = (props) => {
  let { record } = props;

  return (
    <PrintButtonWithoutHeader click={(e)=>showPopupWithoutHeader(record.id)}   />
    
  )
}
  return (
    
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Salary List" filters={<Filters />}  >
      <Datagrid >
        {propsObj.hasEdit && <EditButton />}
        {/* <DisplayFileLink label="" source="id" />
        <DisplayFileLink1 label="" source="id" /> */}
        
        <TextField source="code" label="Code " />
        <DateField  source="salDate" label="Salary Date" locales="fr-FR" />
        {/* <SelectF  source="salaryMonth" label="Salary Month" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input}  choices={ [ { id: '1', name: 'Jan' },  { id: '2', name: 'Feb' } , { id: '3', name: 'March' },  { id: '4', name: 'April' } , { id: '5', name: 'May' } , { id: '6', name: 'June' } , { id: '7', name: 'July' } , { id: '8', name: 'August' } , { id: '9', name: 'Sept' } , { id: '10', name: 'Oct' } , { id: '11', name: 'Nov' } , { id: '12', name: 'Dec' } ]}  onChange={(event)=>handleChange(event,"salaryMonth")}/>   */}
        
        <TextField source="totalGrossSal" label="Total Gross  " />
        <TextField source="totalDeduction" label="Total Deduction " />
        <TextField source="totalPay" label="Total Pay " />
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
    
      </React.Fragment>
  );
};

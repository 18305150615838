import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import React from 'react'
const BankDetail = (props) => {
    let styles = props.styles
    return <View style={styles.bankDetailBox} >
        <Text style={{ fontWeight: "bold", textAlign: "center", textDecoration: "underline" }}>Bank Detail</Text>
    </View>
}
export default BankDetail

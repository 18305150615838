import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const RealizationOrderPatmentFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});


    const TwoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).valueOf();
    }

    const onAmountChangehBillAmt = (e) => {
        let hBillAmt = parseFloat(e.target.value);
        let { hospDisc, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
    }

    const onAmountChangehospDisc = (e) => {
        let hospDisc = parseFloat(e.target.value);
        let { hBillAmt, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
    }

    const onAmountChangepPaidAmt = (e) => {
        let pPaidAmt = parseFloat(e.target.value);
        let { hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
    }


    const onAmountChangesettleAmt = (e) => {
        let settleAmt = parseFloat(e.target.value);
        let { pPaidAmt, hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
    }
    const onAmountChangetdsamt = (e) => {
        let tdsamt = parseFloat(e.target.value);
        let { settleAmt, pPaidAmt, hospDisc, hBillAmt, othDisc,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
    }

    const onAmountChangeuDisAllAmt = (e) => {
        let uDisAllAmt = parseFloat(e.target.value);
        let { claimAmt, disAllAmt } = formData;

        uDisAllAmt = parseFloat(uDisAllAmt);
        claimAmt = parseFloat(claimAmt);
        disAllAmt = parseFloat(disAllAmt);


        let varjDisAllAmt = disAllAmt - uDisAllAmt;
        let per = (uDisAllAmt / claimAmt) * 100;
        per = per.toFixed(2);
        form.change("jDisAllAmt", TwoDecimalPlaces(varjDisAllAmt));
        form.change("uDisAllAmtPer", per);

        // if (per <= 5) {
        //     form.change("rpriotiyId", 17);
        // } else if (per > 5 && per <= 10) {
        //     form.change("rpriotiyId", 16);
        // } else if (per > 10) {
        //     form.change("rpriotiyId", 15);
        // }
    }

    return (
        <div className="payment-field-parent">
            <NumberInput variant="outlined" label="Hospital bill amount" source="hBillAmt"
                fullWidth={true} className={classes.one_4_input} />
            <NumberInput variant="outlined" label="Hospital Discount"
                source="hospDisc" fullWidth={true} className={classes.two_4_input} />
            <NumberInput variant="outlined" label="A. Disc." source="othDisc"
                fullWidth={true} className={classes.three_4_input} />

            <NumberInput variant="outlined" label="Patient Paid Amount"
                source="pPaidAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput disabled={true} variant="outlined" label="Claim Amount" source="claimAmt" fullWidth={true} className={classes.one_4_input} />
            <NumberInput disabled={true} variant="outlined" label="Disallow. Amount" source="disAllAmt" fullWidth={true} className={classes.two_4_input} />
            <NumberInput variant="outlined" label="Settlement Amount"
                source="settleAmt" fullWidth={true} className={classes.three_4_input} />
            <NumberInput variant="outlined" 
                label="Unjustified disallow Amount" initialValue="0" source="uDisAllAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput variant="outlined" disabled={true} label="Justified disallow Amount"
                source="jDisAllAmt" fullWidth={true} className={classes.one_4_input} />

            <NumberInput disabled={true} variant="outlined" label="Unjustified disallow %" source="uDisAllAmtPer" fullWidth={true} className={classes.two_4_input} />
            <TextInput multiline={true} variant="outlined" label="Disallow Details" source="disAllDet" fullWidth={true} className={classes.twice_4_input} />

            <NumberInput variant="outlined" label="TDS Amount"
                
                source="tdsamt"
                fullWidth={true} className={classes.one_4_input} />
            <NumberInput disabled={true} variant="outlined" label="Net Payment" source="payment" fullWidth={true} className={classes.two_4_input} />
            <TextInput variant="outlined" label="NEFT Numbers" source="neftDet" fullWidth={true} className={classes.three_4_input} />
            <DateInput variant="outlined" label="NEFT Date" source="neftDate" fullWidth={true} className={classes.last_4_input} />

            <ReferenceInput disabled={true} variant="outlined" label="Reclaim Priority" source="rpriotiyId" reference="lookups" className={classes.one_4_input}
                filter={{ type: 'RPR' }} fullWidth={true} >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <BooleanInput disabled={true} variant="outlined"
                
                label="Reclaim Raised ?" source="reclRaised" fullWidth={true} className={classes.two_4_input} />
            <DateInput variant="outlined" disabled={(formData.reclRaised == true) ? false : true} label="Reclaim Date" source="reclDt" fullWidth={true} className={classes.three_4_input} />
            <NumberInput variant="outlined" disabled={(formData.reclRaised == true) ? false : true} label="Amt. Reclaimed Agnst Deduction" source="reclAmt" fullWidth={true} className={classes.last_4_input} />

            <NumberInput variant="outlined" label="Reclaim Amount Received" source="reclRecAmt" fullWidth={true} className={classes.one_4_input} />
            <NumberInput variant="outlined" label="Reclaim TDS Amount" source="rTDSAMT" fullWidth={true} className={classes.two_4_input} />
            <TextInput variant="outlined" label="Reclaim NEFT Numbers" source="reclNeftNo" fullWidth={true} className={classes.three_4_input} />
            <DateInput variant="outlined" label="Reclaim NEFT Date" source="reclNeftDt" fullWidth={true} className={classes.last_four_input} />

            <TextInput variant="outlined" multiline={true} label="Reclaim Follow. Rem." source="reclRem" fullWidth={true} />

           



        </div>
    )
}

import React,{useState} from "react";
import {
  FormDataConsumer,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  BooleanInput,
  DateInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const DsrEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }
  const classes = useStyles({});
  const [isHospitalMaster, setHospitalMaster] = useState<boolean>(false);
  const [isProposalsend, setProposalsend] = useState<boolean>(false);
 
  return (
    <Edit title="DSR Edit" {...propsObj}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...propsObj} showDelete={false} />} variant="outlined" >
      <DateInput validate={[required()]} source="date" label="Date" formClassName={classes.one_three_input} disabled={true} />
       <BooleanInput validate={[required()]} source="isExistingHosp" label="Existing Hospital?" formClassName={classes.two_three_input}  onChange={(v) => {  setHospitalMaster(v); }}/> 
        {
          isHospitalMaster===true? 
          <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
          filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Hospital Name" source="existingHospitalId" reference="hospitals" fullWidth={true} formClassName={classes.last_three_input}>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>: <TextInput validate={[required()]} source="hospitalName" label="Hospital"  formClassName={classes.last_three_input}  variant="outlined"/>
          // <ReferenceInput label="Hospital" source="existingHospitalId" reference="hospitals"   formClassName={classes.last_three_input}  sort={{ field: 'name', order: 'ASC' }} >
          // <SelectInput optionText="name" validate={[required()]} variant="outlined"/>
          // </ReferenceInput>: <TextInput validate={[required()]} source="hospitalName" label="Hospital"  formClassName={classes.last_three_input}  variant="outlined"/>
        }
      
        <TextInput  multiline={true} source="hospitalAddress" label="Hospital Address "   fullWidth={true} />
        
          <TextInput source="contactPersonName" label="Contact Person" fullWidth={true} formClassName={classes.one_three_input} />
          <TextInput source="contactNo" label="Contact No" fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput source="emailId" label="Email" fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput source="discussionDetails"   multiline={true} label="Discussion Details" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput source="planofAction"   multiline={true} label="Plan Of Action" fullWidth={true} formClassName={classes.last_inline_input}/>

          <BooleanInput validate={[required()]} source="isProposalsend" label="Proposal Send?" fullWidth={true} formClassName={classes.one_three_input}  onChange={(v) => {  setProposalsend(v); }}/> 
          {isProposalsend===true
          ?
          <DateInput label="Proposal Send Date" source="proposalSendDate" fullWidth={true} formClassName={classes.two_three_input} />
          :<DateInput label="Proposal Send Date" disabled={true}  source="proposalSendDate"  fullWidth={true} formClassName={classes.two_three_input} />}
          
          <NumberInput validate={[required()]} source="conveyance" label="Conveyance" fullWidth={true} formClassName={classes.last_three_input}/> 
          
          <NumberInput source="approvedConveyance" label="Approved Conveyance" fullWidth={true} formClassName={classes.one_three_input}/> 
          <TextInput source="conveyanceDetails"  label="Conveyance Details" fullWidth={true} formClassName={classes.two_three_input}/>
          <DateInput label="Paid On" source="paidOn" fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput source="remarks"   multiline={true} label="Remarks" fullWidth={true} />
          {/* <ReferenceInput label="Executive" source="employeeId" reference="employees" formClassName={classes.one_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" validate={[required()]}  formClassName={classes.one_three_input} />
          </ReferenceInput> */}
          <ReferenceInput perPage={2500} label="Executive" source="employeeId" reference="employees"  formClassName={classes.one_three_input}
            sort={{ field: 'name', order: 'ASC' }}
            >
                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} formClassName={classes.one_three_input}/>
          </ReferenceInput>
        
    
          <BooleanInput validate={[required()]} source="isActive" label=" Active ?" formClassName={classes.two_three_input} /> 
          
          <FormDataConsumer >
              {({ formData, ...rest }) =>
             setProposalsend(formData.isProposalsend)  }
        </FormDataConsumer>
        <FormDataConsumer >
              {({ formData, ...rest }) =>
             setHospitalMaster(formData.isExistingHosp)  }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices, useNotify,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import * as apiClient from "../../common/apiClient";
import MailIcon from '@material-ui/icons/Mail';

const SendEmailButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const sendEmail = () => {
        apiClient.SendHDCQDetEmailLog(props.id, props.mailtype).then(res => {
            notify("Email send successfully.");
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <div className="send-email-button">
            <Button
                color='primery'
                size="medium"
                redirect={ false }
                disabled={props.disabled}
                onClick={ sendEmail }
                startIcon={ <MailIcon /> }
            >Send Email</Button>
        </div>
    )
};

export const RealizationOrderPatmentFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    
    // console.log(formData, "==formData=====");
    const [isImp_IOL_Inj, setIsImp_IOL_Inj] = useState(rest.isImp_IOL_Inj);
    const TwoDecimalPlaces = (value) => {
        return Number(value).toFixed(2).valueOf();
    }

    const setrReclBal = (disAllAmt) => {
        let { rTDSAMT, reclRecAmt, reclAmt } = formData;

        rTDSAMT = parseFloat(rTDSAMT);
        reclAmt = parseFloat(reclAmt);
        reclRecAmt = parseFloat(reclRecAmt);
        disAllAmt = parseFloat(disAllAmt);

        let rReclBal = reclAmt - (reclRecAmt);
        form.change("rReclBal", TwoDecimalPlaces(rReclBal));

    }
    const onAmountChangehBillAmt = (e) => {
        let hBillAmt = parseFloat(e.target.value);
        let { hospDisc, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);

        if (isNaN(vclaimAmount)) {
            vclaimAmount = 0;
        }
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        //form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
        setrReclBal(vDissAllAmount);
    }

    const onAmountChangehospDisc = (e) => {
        let hospDisc = parseFloat(e.target.value);
        let { hBillAmt, othDisc, pPaidAmt, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        if (isNaN(vclaimAmount)) {
            vclaimAmount = 0;
        }
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        //form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
        setrReclBal(vDissAllAmount);
    }

    const onAmountChangepPaidAmt = (e) => {
        let pPaidAmt = parseFloat(e.target.value);
        let { hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, settleAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        if (isNaN(vclaimAmount)) {
            vclaimAmount = 0;
        }
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        //form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
        setrReclBal(vDissAllAmount);
    }


    const onAmountChangesettleAmt = (e) => {
        let settleAmt = parseFloat(e.target.value);
        let { pPaidAmt, hospDisc, hBillAmt, othDisc, tdsamt,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);


        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        if (isNaN(vclaimAmount)) {
            vclaimAmount = 0;
        }
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        //form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
        //setrReclBal(vDissAllAmount);
    }
    const onAmountChangetdsamt = (e) => {
        let tdsamt = parseFloat(e.target.value);
        let { settleAmt, pPaidAmt, hospDisc, hBillAmt, othDisc,
            claimAmt, disAllAmt, jDisAllAmt, uDisAllAmt, reclRecAmt } = formData;

        hBillAmt = parseFloat(hBillAmt);
        hospDisc = parseFloat(hospDisc);
        pPaidAmt = parseFloat(pPaidAmt);
        tdsamt = parseFloat(tdsamt);
        settleAmt = parseFloat(settleAmt);
        reclRecAmt = parseFloat(reclRecAmt);

        let vclaimAmount = hBillAmt - (hospDisc + pPaidAmt);
        if (isNaN(vclaimAmount)) {
            vclaimAmount = 0;
        }
        let vPayment = settleAmt - tdsamt;
        let vDissAllAmount = 0;
        let vReclAmt = 0;
        if (vclaimAmount > 0 && settleAmt > 0) {
            vDissAllAmount = vclaimAmount - (settleAmt + reclRecAmt);
        }
        if ((vclaimAmount - settleAmt) > 0 && settleAmt > 0) {
            vReclAmt = vclaimAmount - settleAmt;
        }
        form.change("claimAmt", TwoDecimalPlaces(vclaimAmount));
        form.change("payment", TwoDecimalPlaces(vPayment));
        form.change("disAllAmt", TwoDecimalPlaces(vDissAllAmount));
        //form.change("jDisAllAmt", TwoDecimalPlaces(vDissAllAmount));
        form.change("reclAmt", TwoDecimalPlaces(vReclAmt));
        setrReclBal(vDissAllAmount);
    }

    const onAmountChangereclAmt = (e) => {
        //reclAmt
        let reclAmt = parseFloat(e.target.value);
        let { rTDSAMT, disAllAmt, reclRecAmt } = formData;

        rTDSAMT = parseFloat(rTDSAMT);
        reclRecAmt = parseFloat(reclRecAmt);
        disAllAmt = parseFloat(disAllAmt);

        reclAmt = parseFloat(reclAmt);

        let reclPayment = reclRecAmt - rTDSAMT;
        let rReclBal = reclAmt - (reclRecAmt);

        form.change("reclPayment", TwoDecimalPlaces(reclPayment));
        form.change("rReclBal", TwoDecimalPlaces(rReclBal));
        //rReclBal
    }


    const onAmountChangereclRecAmt = (e) => {
        let reclRecAmt = parseFloat(e.target.value);
        let { rTDSAMT, disAllAmt, reclAmt } = formData;

        rTDSAMT = parseFloat(rTDSAMT);
        reclRecAmt = parseFloat(reclRecAmt);
        disAllAmt = parseFloat(disAllAmt);

        reclAmt = parseFloat(reclAmt);

        let reclPayment = reclRecAmt - rTDSAMT;
        let rReclBal = reclAmt - (reclRecAmt);

        form.change("reclPayment", TwoDecimalPlaces(reclPayment));
        form.change("rReclBal", TwoDecimalPlaces(rReclBal));
        //rReclBal
    }

    const onAmountChangerTDSAMT = (e) => {
        let rTDSAMT = parseFloat(e.target.value);
        let { reclRecAmt, disAllAmt } = formData;

        rTDSAMT = parseFloat(rTDSAMT);
        reclRecAmt = parseFloat(reclRecAmt);
        disAllAmt = parseFloat(disAllAmt);

        let reclPayment = reclRecAmt - rTDSAMT;
        let rReclBal = disAllAmt - (reclRecAmt);

        form.change("reclPayment", TwoDecimalPlaces(reclPayment));
        form.change("rReclBal", TwoDecimalPlaces(rReclBal));
    }

    const onAmountChangeuDisAllAmt = (e) => {
        let uDisAllAmt = parseFloat(e.target.value);
        let { claimAmt, disAllAmt } = formData;

        uDisAllAmt = parseFloat(uDisAllAmt);
        claimAmt = parseFloat(claimAmt);
        disAllAmt = parseFloat(disAllAmt);

        //jDisAllAmt

        let varjDisAllAmt = disAllAmt - uDisAllAmt;
        let per = (uDisAllAmt / claimAmt) * 100;
        per = per.toFixed(2);
        form.change("jDisAllAmt", TwoDecimalPlaces(varjDisAllAmt));
        form.change("uDisAllAmtPer", per);
        form.change("reclAmt", uDisAllAmt);

        // if (per <= 5) {
        //     form.change("rpriotiyId", 17);
        // } else if (per > 5 && per <= 10) {
        //     form.change("rpriotiyId", 16);
        // } else if (per > 10) {
        //     form.change("rpriotiyId", 15);
        // }
    }

    return (
        <div className="payment-field-parent">



            <NumberInput variant="outlined" label="Hospital bill amount" source="hBillAmt"
                disabled={rest.hasDataEntryEdit}
                onChange={ (e) => {
                    onAmountChangehBillAmt(e);
                } }
                onBlur={ (e) => {
                    form.change("hBillAmt", TwoDecimalPlaces(formData.hBillAmt));
                } }
                fullWidth={ true } className={ classes.one_5_input } 
                
                />
            <NumberInput variant="outlined" label="Hospital Discount"
                onChange={ (e) => {
                    onAmountChangehospDisc(e);
                } }
                onBlur={ (e) => {
                    form.change("hospDisc", TwoDecimalPlaces(formData.hospDisc));
                } }
                source="hospDisc" fullWidth={ true } className={ classes.two_5_input } 
                disabled={rest.hasDataEntryEdit}
                />
            <NumberInput variant="outlined" label="AL. Disc." source="othDisc"
                onBlur={ (e) => {
                    form.change("othDisc", TwoDecimalPlaces(formData.othDisc));
                } }
                fullWidth={ true } className={ classes.three_5_input } 
                disabled={rest.hasDataEntryEdit}
                />

            <NumberInput variant="outlined" label="Patient Paid Amount"
                onChange={ (e) => {
                    onAmountChangepPaidAmt(e);
                } }
                onBlur={ (e) => {
                    form.change("pPaidAmt", TwoDecimalPlaces(formData.pPaidAmt));
                } }
                source="pPaidAmt" fullWidth={ true } className={ classes.four_5_input } disabled={rest.hasDataEntryEdit}/>
            <NumberInput disabled={ true } variant="outlined" label="Claim Amount" source="claimAmt" fullWidth={ true } className={ classes.last_5_input } />
            <div>
                <div style={ { float: "left", width: "19%" } } >
                    { rest.isImp_IOL_Inj === true ?
                        <NumberInput disabled={ false } fullWidth={ true } variant="outlined" label="IMP/IOL/INJ Amt" source="imp_Iol_InjAmt" onBlur={ (e) => {
                            form.change("imp_Iol_InjAmt", TwoDecimalPlaces(formData.imp_Iol_InjAmt));
                        } } />
                        :
                        <NumberInput disabled={ true } fullWidth={ true } variant="outlined" label="imp/Iol/Inj Amt" source="imp_Iol_InjAmt" onBlur={ (e) => {
                            form.change("imp_Iol_InjAmt", TwoDecimalPlaces(formData.imp_Iol_InjAmt));
                        } } /> }
                </div>
                <div style={ { float: "left", width: "75%", marginLeft: "6%" } } >
                   {!rest.hasDataEntryEdit && <ReferenceInput variant="outlined" perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                        validate={ [required()] } fullWidth={ true }
                        filterToQuery={ searchText => ({ 'name~like': searchText }) }
                        label="Scrutiny By" source="scrutiny_By" reference="employees" >
                        <AutocompleteInput
                            optionText="name"
                            shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
                       
                    </ReferenceInput>
                  }
                {rest.hasDataEntryEdit && <ReferenceInput variant="outlined" perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                        validate={ [required()] } fullWidth={ true }
                        filterToQuery={ searchText => ({ 'name~like': searchText }) }
                        label="Scrutiny By" source="scrutiny_By" reference="employees" >
                        <SelectInput optionText="name" disabled={true} />                       
                    </ReferenceInput>
                  }
                </div>
            </div>


            <TextInput multiline={ true } variant="outlined" label="Ailment Diagnosis" source="ailmentDiagnosis" fullWidth={ true } className={ classes.start_thrice_4_input } disabled={rest.hasDataEntryEdit} />
            <TextInput multiline={ false } variant="outlined" label="Treating Doctor Name" source="treatingDrName" fullWidth={ true } className={ classes.last_4_input } disabled={rest.hasScutinyEdit}/>

            <TextInput variant="outlined" label="HDC. Internal Query" multiline={ true } source="hdcqDet" fullWidth={ true } className={ classes.start_thrice_4_input } disabled={rest.hasScutinyEdit}  />
            <DateInput variant="outlined" label="HDC Internal Query Dt " source="hdcqDt" fullWidth={ true } className={ classes.last_4_input } disabled={rest.hasScutinyEdit}/>
            <BooleanInput variant="outlined"
                onChange={ (e) => {
                    if (e && formData.hdcQrDt == null) {
                        form.change("hdcQrDt", new Date());
                    }
                } }
                label="HDC Query Received ?" source="isHdqConf" fullWidth={ true } className={ classes.start_twice_4_input }  disabled={rest.hasScutinyEdit}/>
            <SendEmailButton id={ formData.id } mailtype="HDQUERY" formClassName={ classes.three_4_input } disabled={rest.hasScutinyEdit} />
            <DateInput variant="outlined" disabled={ (formData.isHdqConf == true && rest.hasScutinyEdit==true) ? false : true } label="HDC Query Rcvd. Dt" source="hdcQrDt" fullWidth={ true } className={ classes.last_4_input } />



            <TextInput variant="outlined" label="Insurance Remarks" multiline source="hospqDet" fullWidth={ true } className={ classes.start_thrice_4_input } disabled={rest.hasScutinyEdit} />
            <DateInput variant="outlined" label="Insurance Query Dt" source="hospqDt" fullWidth={ true } className={ classes.last_4_input } disabled={rest.hasScutinyEdit} />
            <BooleanInput variant="outlined"
                onChange={ (e) => {
                    if (e && formData.hospqsDt == null) {
                        form.change("hospqsDt", new Date());
                    }
                } }
                label="Query Submitted by Hospital?" source="isHqConf" fullWidth={ true } className={ classes.start_twice_4_input } disabled={rest.hasScutinyEdit} />
            <SendEmailButton id={ formData.id } mailtype="INSQUERY" formClassName={ classes.three_4_input } disabled={rest.hasScutinyEdit} />
            <DateInput variant="outlined" disabled={ (formData.isHqConf == true && rest.hasScutinyEdit==true) ? false : true } label="Query Submitted Date" source="hospqsDt" fullWidth={ true } className={ classes.last_4_input }  />


            <TextInput multiline={ true } variant="outlined" label="Claim followup History" source="ClaimFollowUpHistory" fullWidth={ true } disabled={rest.hasScutinyEdit} />



            <NumberInput variant="outlined" label="Settlement Amount"
                onChange={ (e) => {
                    onAmountChangesettleAmt(e);
                } }
                onBlur={ (e) => {
                    form.change("settleAmt", TwoDecimalPlaces(formData.settleAmt));
                } }
                source="settleAmt" fullWidth={ true } className={ classes.one_5_input } 
                disabled={rest.hasRealizationEdit}
                />


            <NumberInput variant="outlined" label="TDS Amount"
                onChange={ (e) => {
                    onAmountChangetdsamt(e);
                } }
                onBlur={ (e) => {
                    form.change("tdsamt", TwoDecimalPlaces(formData.tdsamt));
                } }
                source="tdsamt"
                fullWidth={ true } className={ classes.two_5_input } 
                disabled={rest.hasRealizationEdit}
                />

            <NumberInput disabled={ true } variant="outlined" label="Net Payment" source="payment" fullWidth={ true } className={ classes.three_5_input } />
            <TextInput disabled={rest.hasRealizationEdit} variant="outlined" label="NEFT Numbers" source="neftDet" fullWidth={ true } className={ classes.four_5_input } />
            <DateInput  disabled={rest.hasRealizationEdit} variant="outlined" label="NEFT Date" source="neftDate" fullWidth={ true } className={ classes.last_5_input } />



            <NumberInput disabled={ true } variant="outlined" label="Disallow. Amount" source="disAllAmt" fullWidth={ true } className={ classes.one_5_input } />


            <NumberInput variant="outlined" onChange={ (e) => {
                onAmountChangeuDisAllAmt(e);
            } }
                onBlur={ (e) => {
                    form.change("uDisAllAmt", TwoDecimalPlaces(formData.uDisAllAmt));
                } }
                label="Unjustified disallow Amount" initialValue="0" source="uDisAllAmt" fullWidth={ true } className={ classes.two_5_input }
                disabled={rest.hasRealizationEdit}
                 />

            <NumberInput variant="outlined" label="Justified disallow Amount"
                disabled={ true }
                source="jDisAllAmt" fullWidth={ true } className={ classes.three_5_input } 
                
                />

            <NumberInput disabled={ true } variant="outlined" label="Unjustified disallow %" source="uDisAllAmtPer" fullWidth={ true } className={ classes.four_5_input } 
            
            />

            <ReferenceInput variant="outlined" label="Reclaim Priority" source="rpriotiyId" reference="lookups" className={ classes.last_5_input }
                filter={ { type: 'RPR' } } fullWidth={ true } >
                <SelectInput optionText="name" disabled={rest.hasRealizationEdit}/>
            </ReferenceInput> 
            <BooleanInput variant="outlined"
                onChange={ (e) => {
                    if (e && formData.reclDt == null) {
                        form.change("reclDt", new Date());
                    }
                } }
                label="Reclaim Raised ?" source="reclRaised" fullWidth={ true } className={ classes.one_4_input }
                disabled={rest.hasRealizationEdit}
                />

            <TextInput disabled={rest.hasRealizationEdit} multiline={ true } variant="outlined" label="Disallow Details" source="disAllDet" fullWidth={ true } className={ classes.thrice_4_input } /> 

            <DateInput   variant="outlined" disabled={ (formData.reclRaised == true && rest.hasRealizationEdit==false  ) ? false : true } label="Reclaim Date" source="reclDt" fullWidth={ true } className={ classes.one_5_input } />
            <NumberInput variant="outlined"
                disabled={ true } label="Amt. Recl. Agnst Deduction"
                onChange={ (e) => {
                    onAmountChangereclAmt(e);
                } }
                onBlur={ (e) => {
                    form.change("reclAmt", TwoDecimalPlaces(formData.reclAmt));
                } }
                source="reclAmt" fullWidth={ true }
                className={ classes.two_5_input } 
                
                />

            <NumberInput variant="outlined" label="Recl. Settlement Amt."
                disabled={ (formData.reclRaised == true && rest.hasRealizationEdit==false) ? false : true }
                onChange={ (e) => {
                    onAmountChangereclRecAmt(e);
                } }
                onBlur={ (e) => {
                    form.change("reclRecAmt", TwoDecimalPlaces(formData.reclRecAmt));
                } }
                source="reclRecAmt" fullWidth={ true } className={ classes.three_5_input } 

                />
            <NumberInput variant="outlined" label="Reclaim TDS Amount"
                disabled={ (formData.reclRaised == true  && rest.hasRealizationEdit==false) ? false : true }
                onChange={ (e) => {
                    onAmountChangerTDSAMT(e);
                } }
                onBlur={ (e) => {
                    form.change("rTDSAMT", TwoDecimalPlaces(formData.rTDSAMT));
                } }
                source="rTDSAMT" fullWidth={ true } className={ classes.four_5_input } />

            <NumberInput disabled={ true } variant="outlined" label="Recl. Payment" source="reclPayment" fullWidth={ true } className={ classes.last_5_input } />

            <NumberInput disabled={ true } variant="outlined" label="Recl. Amount Balance" source="rReclBal" fullWidth={ true } className={ classes.one_three_input } />

            <TextInput variant="outlined" disabled={ (formData.reclRaised == true  &&   rest.hasRealizationEdit==false) ? false : true } label="Reclaim NEFT Numbers" source="reclNeftNo" fullWidth={ true } className={ classes.two_three_input } />
            <DateInput variant="outlined" disabled={ (formData.reclRaised == true  && rest.hasRealizationEdit==false ) ? false : true } label="Reclaim NEFT Date" source="reclNeftDt" fullWidth={ true } className={ classes.last_three_input } />
            <TextInput variant="outlined" disabled={ (formData.reclRaised == true && rest.hasRealizationEdit==false ) ? false : true } multiline={ true } label="Reclaim Follow. Rem." source="reclRem" fullWidth={ true } />
        </div>
    )
}

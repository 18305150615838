import React from 'react';
import { Edit, SimpleForm, TextInput,NumberInput,BooleanInput, required } from 'react-admin';
import {FormToolbar} from "../../common/FormToolbar";

export const LeaveTypeEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Subject" {...props}>
                <SimpleForm   toolbar={<FormToolbar {...props} />}
                variant="outlined">
                  
                    {/* <TextInput source="id" /> */}
                    <TextInput source="name" label='Leave Type' fullWidth={true} validate={[required()]} />
                    <TextInput source="description" label='Description ' fullWidth={true} validate={[required()]} />
                    <NumberInput source="totalAllowed" label='Total Allowed' fullWidth={true} validate={[required()]} />
                    <BooleanInput source="isActive" validate={[required()]} label="Active" />
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}
import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import { MyDataProvider } from "./providers/MyDataProvider";
import VSDataProvider from "./providers/VSDataProvider";

import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { MainHome } from "./layout/Home";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import roletypes from './screens/roletypes';

import areas from './screens/areas';
import users from './screens/users';
import employee from './screens/employee';
import tpa from './screens/tpa';
import insurer from './screens/insurer';
import corporate from './screens/corporate';
import hospital from './screens/hospital';
import realizeorder from './screens/realize-order';
import hosprealizeorder from './screens/hosp-realize-order';
import hospitalinvoice from './screens/hospitalinvoice';
import leavetype from './screens/leavetype';
import holidaylist from './screens/holidaylist';
import ssheader from './screens/ss-log-hdrs';
import employeeleavelist from './screens/employeeleavelist'; 
import employeeleaveapprove from './screens/employeeleaveapprove'; 
import employeesalary from './screens/employeesalary'; 
import regularization from './screens/regularization'; 
import dsr from './screens/dsr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';
import employeeattendancereqlist from "./screens/employeeattendancereqlist";
import employeeattendancereqapprove from "./screens/employeeattendancereqapprove";





const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);

  }, []);

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource title="Area" name="areas" {...areas} />
        <Resource title="User" name="users" {...users} />
        <Resource title="Employee" name="employees" {...employee} />
        <Resource title="TPA" name="tpas" {...tpa} />
        <Resource title="Insurer" name="insurers" {...insurer} />
        <Resource title="Corporate" name="corporates" {...corporate} />
        <Resource title="Hospital" name="hospitals" {...hospital} />
        <Resource title="Reliaze order" name="realiz-ords"   {...realizeorder} />
        <Resource name="lookups" list={ListGuesser} edit={EditGuesser} />
        <Resource name="realiz-ord-dets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="realiz-ord-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource title="Role Type" name="role-types" {...roletypes} />
        <Resource title="Hospital" name="hospital-dets" {...roletypes} />
        <Resource name="hospital-docs" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="configs" {...configs} />
        <Resource name="dsrs" {...dsr} />
        <Resource name="hospital-ins-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="hospital-real-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="hospital-realiz-ords" {...hosprealizeorder}  />
        <Resource name="hospital-realiz-ords" {...hosprealizeorder}  />
        <Resource name="hospital-invoice" {...hospitalinvoice}/>
        <Resource name="leave-types" {...leavetype}/>
        <Resource name="holiday-lists" {...holidaylist}/>
        <Resource name="employee-leaves" {...employeeleavelist}/>
        <Resource name="employee-leaves-approve"  {...employeeleaveapprove}/>
        <Resource name="employee-sal"  {...employeesalary}/>
        <Resource name="employee-sal-dets"  list={ListGuesser} edit={EditGuesser}  />
        <Resource name="ss-log-hdrs" {...ssheader}  />
        <Resource name="ss-log-dets" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="ss-upload-logs" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="courier-destinations" list={ListGuesser} edit={EditGuesser}  />
        <Resource name="courier-wt-slabs" list={ListGuesser} edit={EditGuesser}  />
        
        <Resource name="emp-attend-logs" {...regularization}  />
        <Resource name="employee-attendance-regularizes" {...employeeattendancereqlist}/>
        <Resource name="employee-attendance-regularizes-approve" {...employeeattendancereqapprove}/>
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;

import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";


const Filters = props => (
    // <Filter {...props} variant="outlined">
    //     <SearchInput variant="outlined" label="Country Name" source="name~like" alwaysOn />
    // </Filter>
    <></>
);
export const CustomList = (props) => {
  return (
    <List {...props} bulkActionButtons={false} title="Attendance Regularization" filters={<Filters />}  >
      <Datagrid rowClick="edit">
        <EditButton />
        <TextField source="code" label="Country Code" />
        <TextField source="name" label="Country Name" />
        <ReferenceField source="currencyId" reference="currency" link={false} label="Currency" >
                        <TextField source="currencyName" />
                    </ReferenceField>
        <ImageField source="countryFlag" label="Flag" />
        
        <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/>        
        
      </Datagrid>
    </List>
  );
};

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

    const handleConfirm = () => {
        props.confirm();
    };
    const handleConfirm1 = () => {
        props.confirm1();
    };
    const handleCancel = () => {
        props.cancel();
    };

    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 style={{color:"#000",paddingLeft:140}}>{props.message}</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus>
                        {props.confirmTitle}
                    </Button> */}
                    <div style={{width:"50px"}}></div>
                     <Button variant="contained" onClick={handleConfirm1} color="primary">
                    {props.confirmTitle1}
                    </Button>
                    <div style={{width:"50px"}}></div>
                    {props.showCancel && <Button variant="contained" onClick={handleCancel} color="primary">
                        {props.cancelTitle}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}


export function ListDialog(props) {

    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<any>(0);

    const handleConfirm = () => {
        props.confirm();
    };

    const handleCancel = () => {
        props.cancel();
    };

    const moveSelected = (keycode) => {
        if (keycode == 40 && selectedIndex < (props.items.length - 1)) {
            setSelectedItem(props.items[selectedIndex + 1]);
            setSelectedIndex(selectedIndex + 1);
        } else if (keycode == 38 && selectedIndex > 0) {
            setSelectedItem(props.items[selectedIndex - 1]);
            setSelectedIndex(selectedIndex - 1);
        } else if (keycode == 13) {
            props.onItemClick(selectedItem);
        }
    }
    useEffect(() => {
        if (!props.items) return;
        if (props.items.length > 0) {
            setSelectedIndex(0);
            setSelectedItem(props.items[0]);
        }
    }, [props.items])
    return (
        <div onKeyDown={(e) => { 
            //moveSelected(e.which);
         }} >
            <Dialog
                open={props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.message && <h4 style={{ color: "#000" }}>{props.message}</h4>}
                        <div className='dialog-items'>
                            {props.items.map((item, index) => {
                                //console.log(selectedItem, "===selectedItem===");
                                //console.log(item, "===item===");
                                return (
                                    <div className={`item ${(index == selectedIndex) ? "" : ""}`} key={index}

                                        onClick={() => { props.onItemClick(item); }}>
                                        <div className='item-name'>{item[props.itemName]}</div>
                                        {props.showDescription && <div className='item-description'>{item[props.itemDescription]}</div>}
                                    </div>
                                )
                            })}

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.showConfirm && <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus>
                        {props.confirmTitle}
                    </Button>}
                    <div style={{ width: "50px" }}></div>
                    {props.showCancel && <Button variant="contained" onClick={handleCancel} color="primary">
                        {props.cancelTitle}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
// CustomCalendar.js
import React, { useState, useEffect } from 'react';
import './CustomCalendar.css';
import * as apiClient from "../../common/apiClient";
import moment from 'moment';

const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const DayCell = ({ date, onClick, status, color }) => {
  return (
    <div className={`calendar-cell ${date ? '' : 'empty'}`} onClick={() => date && onClick(date)}>
      <div className="day-label">{date && date.getDate()}</div>
      <div className={`color-circle ${color}`}></div>
      

    </div>
  );
};

const LegendItem = ({ label, count, color }) => {
  return (
    <div className="legend-item">
      <div className={`color-circle ${color}`}></div>
      <span>{label}</span>
      <span>{count}</span>
    </div>
  );
};

 

const CustomCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [Attleavecalender, setAttleavecalender] = useState([]);
  const [AttLeaveSummary, setAttLeaveSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loadEmployeeCalender = async (year, month) => {
    let userid = localStorage.getItem("userId");
    setIsLoading(true);
    let data = [];
    setAttleavecalender([...data]);
    setAttLeaveSummary([...data]);
    apiClient.get_employeecalender(year, month, userid).then(res => {
      const attLeavesummary = JSON.parse(res[0].attLeavesummary);
      const attleavecalender = JSON.parse(res[0].attleavecalender);
      setAttLeaveSummary(attLeavesummary);
      setAttleavecalender(attleavecalender);

      setIsLoading(false);
      console(JSON.parse(res[0].attleavecalender), "attleavecalender");
    }).catch(err => {
      setIsLoading(false);
    });
  }
  useEffect(() => {
    const _year = selectedDate.getFullYear();
    const _month = selectedDate.getMonth();
    loadEmployeeCalender(_year, _month + 1);
  }, [selectedDate]);

  


  const generateCalendar =  (year, month) => {

    const totalDays = daysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const calendar = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(null);
    }

    for (let i = 1; i <= totalDays; i++) {
      calendar.push(new Date(year, month, i));
    }

    while (calendar.length % 7 !== 0) {
      calendar.push(null);
    }

    console.log(calendar)

    return calendar;
  };

  const handleDateClick = (date) => {
    // setSelectedDate(date);
    // Add your logic for handling date clicks
  };

  const renderCalendarHeader = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    // loadEmployeeCalender(year, month - 1);
    return (
      <div className="calendar-header">
        <button className="nav-button" onClick={() => {
          setSelectedDate(new Date(year, month - 1));
          loadEmployeeCalender(year, month );
        }
        }>
          &lt;
        </button>
        <span className="current-month">{months[month]} {year}</span>
        <button className="nav-button" onClick={() => {
          setSelectedDate(new Date(year, month + 1));
          loadEmployeeCalender(year, month + 1);
        }

        }>
          &gt;
        </button>
      </div>
    );
  };

  const renderDaysOfWeek = () => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek.map((day) => <div key={day} className="day-of-week">{day}</div>);
  };




  const renderCalendarCells =  () => {
    let data = [...Attleavecalender];
    debugger
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const calendar =  generateCalendar(year, month, data); 
    return calendar.map((date, index) => {
      let currentDate = moment(date).format('DD-MM-YYYY');  
      let random_Status = "";
      let color = "";
      let Cells=[];
      if (date) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.date == currentDate) {
           // random_Status = element.status;
            color = element.color;
            break;
          }
        }
      }



      return (
        <> 
          <DayCell
            key={index}
            date={date}
            onClick={handleDateClick}
            color={color}
          />
        </> 
      );
    });
  };

  const renderLegend = () => {
    return (
      <div className="legend"> 
        {
          AttLeaveSummary.map((item) => {
            return <LegendItem label={item["keyname"]} count={item["keyvalue"]} color={item.color} />
          }
          )
        } 
      </div>
    );
  }; 
  return ( 
    <div className="custom-calendar"> 
    
      {renderLegend()}
      {renderCalendarHeader()}
      <div className="days-of-week">{renderDaysOfWeek()}</div>
      {!isLoading && Attleavecalender.length>0 && <div className="calendar-grid">{renderCalendarCells()}</div>} 
    </div> 
  );
};

export default CustomCalendar;

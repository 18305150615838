import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  required,
  useNotify,
  useRedirect,

} from "react-admin";
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";

export const CustomEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = (formdata) => {
    let userId: any
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    formdata.modifyBy = userId;
    formdata.modifyDate = new Date();
    formdata.approvedDate = formdata.attendanceDate
    
    
    
    if (new Date(formdata.attendanceDate) > new Date()) {
      notify("You cannot apply future date", "warning")
      return;
    }
    formdata = apiClient.padDate(formdata,'attendanceDate'); 
    formdata =apiClient.padDate(formdata,'approvedDate');
    apiClient.updateattendanceregularizes(formdata).then(res => {
      notify("Attendance Regularization  is updated")
      redirect("/employee-attendance-regularizes");
    }).catch(err => {
      console.log(err);
    });
  }
  return (
    <Edit title="Attendance Regularization  Edit" {...props}>
      <SimpleForm onSubmit={handleSubmit} redirect="list" toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
         
      <DateInput  locales="fr-FR" variant="outlined" label="Attendance Date" source="attendanceDate" fullWidth={true} validate={[required()]}  /> 
      <ReferenceInput label="Attendance Type"  fullWidth={true}   
                    perPage={5000} source="attendanceTypeId"
                    filter={{ type: 'RegLt' }}

                    sort={{ field: 'name', order: 'ASC' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"   />
                </ReferenceInput>
        <SelectInput label="Location" source="location" fullWidth={true} 
              choices={[
                { id: 'WFH', name: 'WFH' },
                { id: 'WFO', name: 'WFO' },
                { id: 'WFF', name: 'WFF' }
              ]} /> 
        
        <TextInput source="remarks" label='Remarks' fullWidth={true} />  
      </SimpleForm>
    </Edit>
  );
};

import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'
import AlertDialog, { ListDialog } from './Dialog';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  AutocompleteInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import InvoicePrint from "./InvoicePrint/InvoicePrint";
import InvoicePrint2 from "./InvoicePrint2/InvoicePrint";
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      marginRight: 5,
      
      width: 650,
  },
}));
const Filters = props => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Perfoma Invoice No" source="perfInvoiceNo~like" alwaysOn /> 
  <SearchInput variant="outlined" label="Invoice No" source="invoiceNo~like" alwaysOn /> 
  
  
  <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true}  allowEmpty alwaysOn>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
</Filter>
);
export const HospitalInvoiceList = (props) => {
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintWithOutHeader, setIsPrintWithOutHeader] = useState(false);
  const [invoiceId, setinvoiceId] = useState(0);
  const [printType, setPrintType] = useState("");
  const [openPrintType, setOpenPrintType] = useState(false);
  const [invoiceType,setInvoiceType]=useState("")
  
  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? true : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrintWithOutHeader(false);
  }
   
const PrintButton=(props)=>( 
  <Button onClick={props.click}  > Print With Header </Button>
) 
const PrintButtonWithoutHeader=(props)=>( 
  <Button onClick={props.click}  > Print  </Button>
) 
  const onCancel = () => {
    setIsPrint(false)
  }
  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
}, []);
function showPopup(id){
    
  setinvoiceId(id)
  //setIsPrint(true);
  setOpenPrintType(true);
  setIsPrintWithOutHeader(false);
  setInvoiceType("WH")
}
function showPopupWithoutHeader(id){
    
  setinvoiceId(id)
  setIsPrint(false);
  //setIsPrintWithOutHeader(true);
  setInvoiceType("WTH")
  setOpenPrintType(true);
}

const DisplayFileLink = (props) => {
  let { record } = props;

  return (
    <PrintButton click={(e)=>showPopup(record.id)}   />
    
  )
}
const DisplayFileLink1 = (props) => {
  let { record } = props;

  return (
    <PrintButtonWithoutHeader click={(e)=>showPopupWithoutHeader(record.id)}   />
    
  )
}
  return (
    
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Hospital List" filters={<Filters />}  >
      <Datagrid >
        {propsObj.hasEdit && <EditButton />}
        <DisplayFileLink label="" source="id" />
        <DisplayFileLink1 label="" source="id" />
        {/* <TextField source="perfInvoiceNo" label="Perfoma Invoice No" />
        <DateField  source="perfInvoiceDate" label="Perf Invoice Date" locales="fr-FR" /> */}
        <BooleanField source='isGenerateInv' label='Generated Invoice' />
        <TextField source="invoiceNo" label="Invoice No" />
        <DateField  source="invoiceDate" label="Invoice Date" locales="fr-FR" />
        
        <ReferenceField label="Hospital" source="hospitalId" reference="hospitals" link={false}>
          <TextField source="name" />
        </ReferenceField>
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
    <Drawer
    anchor="right"
    onClose={handleClose}
    classes={{ paper: myclasses.drawerPaper, }}
    open={isPrint || isPrintWithOutHeader }>
      { isPrint && <InvoicePrint
                      onClose={closeEdit}
                      onCancel={onCancel}
                      invoiceId={invoiceId}
                      isOpen={isPrint}
                      printType={printType}
                      {...props} />}
      { isPrintWithOutHeader && <InvoicePrint2
                      onClose={closeEdit}
                      onCancel={onCancel}
                      invoiceId={invoiceId}
                      isOpen={isPrint}
                      printType={printType}
                      {...props} />}
      </Drawer>
      <AlertDialog showCancel={true} confirmTitle="Print Performa"   confirmTitle1="Print Invoice"  cancelTitle="Cancel Print" title="" message="Please Choose Type" open={openPrintType}
        confirm={() => {
          debugger
          if(invoiceType=="WH")
          {

            setIsPrint(true);
            setIsPrintWithOutHeader(false);
          }
          if(invoiceType=="WTH")
          {
            setIsPrint(false);
            setIsPrintWithOutHeader(true);
          }
          setPrintType("PERFORMA");
          setOpenPrintType(false)
        }}
        confirm1={() => {
          if(invoiceType=="WH")
          {

            setIsPrint(true);
            setIsPrintWithOutHeader(false);
          }
          if(invoiceType=="WTH")
          {
            setIsPrint(false);
            setIsPrintWithOutHeader(true);
          }
          setPrintType("INVOICE");
          setOpenPrintType(false);
        }}
        cancel={() => {
          setOpenPrintType(false);
          setPrintType("");
          setIsPrint(false);
          
        }} />
      </React.Fragment>
  );
};

import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import React from 'react'
const BankAccountDetail = (props) => {
    let styles = props.styles
    return <View>
        <View style={styles.bankAccountDetailBox} >
        <View style={styles.bankAccountDetailBox1}>
            <Text>A/C Holder Name</Text>
        </View>
        <View style={styles.bankAccountDetailBox2}>
            <Text>Bank Name</Text>
        </View>
        <View style={styles.bankAccountDetailBox3}>
            <Text>A/C No</Text>
        </View>
        <View style={styles.bankAccountDetailBox4}>
            <Text>IFSC No</Text>
        </View>
    </View>
    <View style={styles.bankAccountDetailBox} >
        <View style={styles.bankAccountDetailBox1}>
            <Text>{props.data.AccountHolderName}</Text>
        </View>
        <View style={styles.bankAccountDetailBox2}>
        <Text>{props.data.BankName}</Text>
        </View>
        <View style={styles.bankAccountDetailBox3}>
        <Text>{props.data.AccountNumber}</Text>
        </View>
        <View style={styles.bankAccountDetailBox4}>
        <Text>{props.data.AccountIFSCCode}</Text>
        </View>
    </View>
    </View>
}
export default BankAccountDetail

import React, { useState, useCallback } from 'react';
import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput, useNotify, useRedirect,
  ReferenceInput,
  SelectInput, DateField, AutocompleteInput,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict";
import { useStyles } from "../../formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common/CDeleteButton";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import RealizationOtherDocumentsCreate from "./RealizationOtherDocumentsCreate";
import RealizationDocumentsCreate from "./RealizationDocumentsCreate";
import * as apiClient from "../../common/apiClient";
import RealizationDocumentEdit from "./RealizationDocumentEdit";
import { RealizationOrderPatmentFields } from "./RealizationOrderPatmentFields";
import { RealizationOrderOtherFields } from "./RealizationOrderOtherFields";
import { RealizationPrint } from "./RealizationPrint";
import { PrintTPAChecklist } from "./PrintTPAChecklist";
import moment from 'moment';

import CorporateCreate from './CorporateCreate'
import AddIcon from '@material-ui/icons/Add';
import  DispatchFields  from './DispatchFields';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}

const UploadDocument = (props) => {
  let { record } = props;
  return (
    <div className="upload-document" onClick={() => {
      props.onClick(record);
    }}>Edit Document</div>
  )
}

const DeleteDocumentButton = (props) => {
  let { record } = props;
  return (
    <input type="checkbox" onChange={(e) => {
      props.onSelectedDocument(record);
    }} />
  )
}


export const RealizeOrderEdit = (props) => {
  const [isImp_IOL_Inj, setIsImp_IOL_Inj] = useState(false);
  let propsObj = { ...props };
  let hasForwardingView=true;
  let hasForwardingCreate=true;
  let hasForwardingEdit=true;
  let hasOtherDocView=true;
  let hasOtherDocCreate=true;
  let hasOtherDocEdit=true;
  let hasOtherDocDelete=true;
  let hasDataEntryEdit=false;
  let hasScutinyEdit=false;
  let hasRealizationEdit=false;
  
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.hasView == 1) ? true : false;
      propsObj.hasShow = (p.hasView == 1) ? false : false;
      propsObj.hasEdit = (p.hasEdit == 1) ? true : false;
      propsObj.hasDelete = (p.hasDelete == 1) ? true : false;
      propsObj.hasCreate = (p.hasCreate == 1) ? true : false;
      propsObj.hasExport = (p.hasExport == 1) ? true : false; 
      hasForwardingView= (p.hasForwardingView == 1) ? true : false;
      hasForwardingCreate= (p.hasForwardingCreate == 1) ? true : false;
      hasForwardingEdit= (p.hasForwardingEdit == 1) ? true : false;
      hasOtherDocView= (p.hasOtherDocView == 1) ? true : false;
      hasOtherDocCreate= (p.hasOtherDocCreate == 1) ? true : false;
      hasOtherDocEdit= (p.hasOtherDocEdit == 1) ? true : false;
      hasOtherDocDelete= (p.hasOtherDocDelete == 1) ? true : false;
      hasDataEntryEdit= (p.hasDataEntryEdit == 1) ? false : true;
      hasScutinyEdit= (p.hasScutinyEdit == 1) ? false : true;
      hasRealizationEdit= (p.hasRealizationEdit == 1) ? false : true;
    }
  }
  let isAdmin = false;
  let RoleTypeId = localStorage.getItem("RoleTypeId"); 
  let AuthType = localStorage.getItem("AuthType"); 
  
  
  if(RoleTypeId != null && RoleTypeId == "1"){
    isAdmin = true;
  }
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isaddDocuments, setIsAddDocuments] = useState(false);
  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [isEditDocumentRecord, setIsEditDocumentRecord] = useState(0);
  const [isaddOtherDocuments, setIsAddOtherDocuments] = useState(false);
  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const [selectedDocumentsId, setSelectedDocumentsId] = useState<any[]>([]);
  const [isPrint, setPrint] = useState(false);
  const [isPrintTPA, setPrintTPA] = useState(false);
  const [tpaId, setTPAID] = useState(0);
  const [configValue, setConfigValue] = useState(0);
  const handleOnChange =  (e) => {

    apiClient.getHospitalData(e).then(r => {
     if (r.length>0)
     {
       setIsImp_IOL_Inj(r[0].isImp_IOL_Inj)
     }
   })
   //alert(e)
   // if (e.target.value == 4 || e.target.value===5 ) {
     
   //   setChecked(false);
   // }
   // else{
   //   setChecked(true);
   // }
 };
  const SelectDeselectDocument = (record) => {
    var ids: any[] = [...selectedDocumentsId];
    const id: any = record.id;
    const index = ids.indexOf(id);
    if (index > -1) {
      ids.splice(index, 1);
    } else {
      ids.push(id);
    }
    setSelectedDocumentsId(ids);
  }
  const onDocumentDelete = async () => {
    var ids: any[] = [...selectedDocumentsId];
    if (ids.length == 0) {
      alert("Please select some documents.");
      return;
    }
    var cn = window.confirm("Are you sure you want to delete the selected documents?");
    if (!cn)
      return;
    ids.forEach(async item => {
      await apiClient.deleteRealizationDocument(item);
    });
    refresh();
  }

  const addFromSetup = (id) => {
    if (configValue == 0) {
      alert("Please select the config.");
      return;
    }
    let userId:any = localStorage.getItem("userId")? localStorage.getItem("userId"):0;
    apiClient.addFromSetup(id, configValue,userId).then(res => {
      refresh();
    }).catch(err => {
      console.log(err);
    });
  }
  const onCloseDocumentForm = () => {
    setIsAddDocuments(false);
    setIsEditDocuments(false);
    setPrint(false);
    setPrintTPA(false);
    refresh();
  }
  const Print = (id) => {
    setPrint(true);
  }
  const PrintTPA = (id) => {
    
    setPrintTPA(true);
  }
  const onCloseOtherDocumentForm = () => {
    setIsAddOtherDocuments(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
    setIsAddCorportate(false);
    setPrint(false);
    setPrintTPA(false);
    
  }

  const updateIsactive=(data:any,event)=>{

    let m = moment();
    let userId:any = localStorage.getItem("userId")? localStorage.getItem("userId"):0;

    
    data.isActive=event;
    data.modifyDate = m.toISOString();
    data.modifyBy = parseInt(userId?userId:0);
    apiClient.updateEntiyRecord("realiz-ord-dets", data.id, data)
        .then(res => {
            console.log(res);
            refresh()
            //props.onClose();
        })
        .catch(err => {
            console.error(err);
        });

}
  
  const CustomCheckBox = (props) => {
    let { record } = props;
    return (
      <input type="checkbox" checked={record.isActive}  onChange={(e) =>updateIsactive(record,e.target.checked)} />
    )
  }
  const handleClose = useCallback(() => {
    setIsAddDocuments(false);
    setIsAddOtherDocuments(false);
    setIsEditDocuments(false);
    setPrint(false);
    setIsAddCorportate(false);
  }, []);
  const redirectMe = (basePath, id, data) => {
    console.log(basePath);
    console.log(id);
    console.log(data);
  };
  const validateUserCreation = (values) => {
    const errors: any = {};
    //console.log(values);
    if (values.uDisAllAmt > 0 && values.rpriotiyId == 14) {
      errors.rpriotiyId = ["Please Change the Recl. Priority"];
    }
    return errors
  };
  return (
    <React.Fragment>
      <Edit title="Realize Order Edit"

        {...propsObj} undoable={false}>
        <TabbedForm
          validate={validateUserCreation}
          redirect={false} variant="outlined" toolbar={<FormToolbar hideSave={AuthType=="1"?true:false} {...propsObj} showDelete={false} />}>
          <FormTab label="Basic Details">
            <TextInput validate={[required(), minLength(3)]} source="billNo" label="Record No" fullWidth={true} formClassName={classes.one_4_input} disabled={true}/>
            <TextInput validate={[required(), minLength(3)]} source="hBillNo" label="Hospital Bill No" fullWidth={true} formClassName={classes.two_4_input} disabled={hasDataEntryEdit}/>

            <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.three_4_input} disabled={hasDataEntryEdit}/>
            <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.last_4_input} disabled={hasDataEntryEdit}/>


            <TextInput source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.one_4_input} disabled={hasDataEntryEdit}/>
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  validate={[required()]} 
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.two_4_input}
              filter={{ isActive: 1 }}
              >
            {!hasDataEntryEdit? <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />:
            <SelectInput optionText="name" disabled={true}/>}
            </ReferenceInput>

            <DateInput validate={[required()]} label="DOA" source="doa" fullWidth={true} formClassName={classes.three_4_input} disabled={hasDataEntryEdit}/>
            <DateInput validate={[required()]} label="DOD" source="dod" fullWidth={true} formClassName={classes.last_4_input}  disabled={hasDataEntryEdit}/>

            <TextInput validate={[required(), minLength(3)]} label="Card No" source="cardNo" fullWidth={true} formClassName={classes.one_three_input} disabled={hasDataEntryEdit}/>
            <TextInput validate={[required(), minLength(3)]} label="CCN No" source="ccnno" fullWidth={true} formClassName={classes.two_three_input} disabled={hasDataEntryEdit}/>

            <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              validate={[required()]} label="TPA Name" source="tpaId" reference="tpas" fullWidth={true} formClassName={classes.last_three_input}
              filter={{ isActive: 1 }}
              >
              {!hasDataEntryEdit? <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />:
            <SelectInput optionText="name" disabled={true}/>}
            </ReferenceInput>

            <TextInput validate={[required(), minLength(3)]} source="policyNo" fullWidth={true} formClassName={classes.one_three_input} disabled={hasDataEntryEdit}/>
            <DateInput  source="policyFrom" fullWidth={true} formClassName={classes.two_three_input} disabled={hasDataEntryEdit}/>
            <DateInput  source="policyTo" fullWidth={true} formClassName={classes.last_three_input} disabled={hasDataEntryEdit}/>
            

            <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              validate={[required()]} label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.first_inline_input}
              filter={{ isActive: 1 }}
              >
              {!hasDataEntryEdit? <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />:
            <SelectInput optionText="name" disabled={true}/>}
            </ReferenceInput>
            {/* <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput> */}
            <FormDataConsumer formClassName={classes.last_inline_input}>
              {formDataProps => (
                <div style={{ display: "flex", flex: "rows" }}>
                  <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}
                    filter={{ isActive: 1 }}
                    >
                    {!hasDataEntryEdit? <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />:
            <SelectInput optionText="name" disabled={true} variant="outlined"/>}
                  </ReferenceInput>
                  {!hasDataEntryEdit&& <Button onClick={() => { setIsAddCorportate(true) }}   ><AddIcon /></Button>}
                </div>
              )}
            </FormDataConsumer>

            <DateInput validate={[required()]} label="Received At HDC" source="frecDate" fullWidth={true} formClassName={classes.one_4_input}  disabled={hasDataEntryEdit}/>
            <DateInput label="Soft File Sub. Date" source="fVSubDate" fullWidth={true} formClassName={classes.two_4_input}  disabled={hasDataEntryEdit}/>
            <DateInput label="Phy File Sub Date" source="fSubDate" fullWidth={true} formClassName={classes.three_4_input}  disabled={hasDataEntryEdit}/>

            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Status" source="statusId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" disabled={hasDataEntryEdit} />
            </ReferenceInput>

            <FormDataConsumer>
              {formDataProps => (
                  <RealizationOrderPatmentFields hasDataEntryEdit={hasDataEntryEdit} hasScutinyEdit={hasScutinyEdit}  hasRealizationEdit={hasRealizationEdit} {...formDataProps}  isImp_IOL_Inj={isImp_IOL_Inj}/>
              )}
            </FormDataConsumer>

            <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderOtherFields {...formDataProps} hasRealizationEdit={hasRealizationEdit} />
              )}
            </FormDataConsumer>

          </FormTab>

          {hasForwardingView &&
          <FormTab label="Forwarding Documents">
            <div style={{ flex: 1, width: "50%" }}>
             {hasForwardingCreate && <Button onClick={() => { setIsAddDocuments(true); }}>Add New</Button>}
             {hasForwardingCreate && <Button onClick={() => { addFromSetup(props.id); }}>Add From Setup</Button>}
             {hasForwardingCreate && <ReferenceInput variant="outlined" onChange={(e) => { setConfigValue(e.target.value); }}
                sort={{ field: 'mconame', order: 'ASC' }}
                label="Setup Config" source="configId" reference="configs" formClassName={classes.first_inline_input} fullWidth={true} >
                <SelectInput optionText="mconame" />
              </ReferenceInput>
            }
              <div>
                
              
                <Button onClick={() => { Print(props.id); }}>Print Dispatch Checklist</Button>
                {/* {<Button onClick={() => { onDocumentDelete(); }}>Delete Selected Documents</Button>} */}
              </div>
            </div>

            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="realiz-ord-dets"
              target="realizordId">
              <Datagrid>
                {/* <CDeleteButton {...props} /> */}
                {/* {<DeleteDocumentButton label="Delete" onSelectedDocument={(record) => {
                  //console.log(record);
                  SelectDeselectDocument(record);
                }} />} */}
                {<CustomCheckBox label="Select"/>}
                
                {/* <TextField label="ITP" source="itp" />
                <TextField label="BillNo" source="billNo" /> */}
                <DateField showTime={true} label="Entry Date" source="entryDt" locales="fr-FR"  />
                <TextField label="Document Type" source="docType" />
                <TextField label="No Of Pages" source="docValue" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                {hasForwardingEdit &&  <UploadDocument onClick={(record) => { setIsEditDocumentRecord(record); setIsEditDocuments(true); }} label="Upload Document" />}
                  
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  showTime={true} source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField showTime={true}  source="modifyDate" label="Updated Date" locales="fr-FR" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          }
         {hasOtherDocView && 
          <FormTab label="Other Documents">
              
              
               <FormDataConsumer fullwidth={true}>
              {formDataProps => (
                <DispatchFields hasOtherDocEdit={hasOtherDocEdit} {...formDataProps} />
              )}
             
            </FormDataConsumer> 
                    <FormDataConsumer>
              {formDataProps => ( setTPAID((formDataProps.formData.tpaId)) 
              )}
            </FormDataConsumer>
            <FormDataConsumer>
              {formDataProps => ( handleOnChange((formDataProps.formData.hospitalId)) 
              )}
            </FormDataConsumer>
            
            <div style={{ flex: 1 }}>
             {hasOtherDocCreate && <Button style={{marginRight:"10"}}  onClick={() => { setIsAddOtherDocuments(true); }}>Add New Document</Button>}
             {(tpaId===12 ||  tpaId===15)?  <Button onClick={() => { PrintTPA(props.id); }}>Print TPA Declaration</Button>:null}
            </div>
            <ReferenceManyField fullWidth={true}
              addLabel={false}
              sort={{ field: 'id', order: 'ASC' }}
              reference="realiz-ord-docs"
              target="realizOrdId">
              <Datagrid>
                {hasOtherDocDelete && <CDeleteButton {...props} />}
                <ReferenceField label="Document Type" source="typeId"
                  filter={{ type: 'DT' }}
                  reference="lookups" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="Remarks" source="remarks" />
                <DisplayFileLink label="Document URL" source="docUrl" />
                <DateField showTime={true} label="Entry Date" source="createdDate"  locales="fr-FR" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        }

        </TabbedForm>
      </Edit >
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isaddDocuments || isaddOtherDocuments || isEditDocuments || isPrint || isAddCorportate || isPrintTPA} >

        {isaddOtherDocuments && <RealizationOtherDocumentsCreate
          onClose={onCloseOtherDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isaddDocuments && <RealizationDocumentsCreate
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isEditDocuments && <RealizationDocumentEdit
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          record={isEditDocumentRecord}
          {...props} />}
        {isPrint && <RealizationPrint
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
          {isPrintTPA && <PrintTPAChecklist 
          tpaId={tpaId}
          onClose={onCloseDocumentForm}
          onCancel={onCancel}
          realizOrdId={props.id}
          {...props} />}
        {isAddCorportate && <CorporateCreate onClose={handleClose} />}
      </Drawer>
    </React.Fragment>
  );
};

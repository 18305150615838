import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const CompanyDocuments = props => {
    const classes = useStyles({});
    
    const handleSubmit = async (formdata) => {
         console.log(formdata);
          
        let data = {
            "configId": parseInt(props.configId),
            "docType": formdata.docType,
            "docValue": parseInt(formdata.docValue),
            "isActive":true,
            
        }
        
        
        apiClient.addEntiyRecord("configdets", data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">
                <TextInput validate={[required()]}  source="docType" label="Document name" fullWidth={true} />
                <TextInput  validate={[required()]}  source="docValue" label="No of Pages" fullWidth={true} /> 
                
                 

                 

            </SimpleForm>
        </Edit>
    );
}
export default CompanyDocuments;
import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const RoleTypesCreate = props => (
    <Create { ...props } title="Role Types" redirect={ false } successMessage="Role Type Updated" undoable={ false }>
        <SimpleForm redirect="list"
            variant="outlined">
            <TextInput validate={ required() } source="roleName" label="Role Name" />
            
        </SimpleForm>
    </Create>
);

import React,{useCallback,useState} from "react";

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,AutocompleteInput,
  SelectInput, DateField, Create,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab,
} from "react-admin";
import { Drawer } from '@material-ui/core';
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"
import { useStyles } from "../../formStyles";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { RealizationOrderPatmentFields } from "./RealizationOrderPatmentFields";
import { RealizationOrderOtherFields } from "./RealizationOrderOtherFields";
import * as apiClient from "../../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';
import CorporateCreate from'./CorporateCreate'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,    
    width: 850,
  },
}));

export const RealizeOrderCreate = (props) => {
  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const refresh = useRefresh();
  const classes = useStyles({});
  const myclasses = myStyles({});
  const [isImp_IOL_Inj, setIsImp_IOL_Inj] = useState(false);

  
  const handleOnChange =  (e) => {

     apiClient.getHospitalData(e).then(r => {
      if (r.length>0)
      {
        setIsImp_IOL_Inj(r[0].isImp_IOL_Inj)
      }
    })
    
  };
  let defaultValues = {
    itp: "I1",
    date: moment().toISOString(),
    hospDisc: 0,
    claimAmt: 0,
    uDisAllAmt: 0,
    jDisAllAmt: 0,
    uDisAllAmtPer: 0,
    rpriotiyId: 14,
    hospqDet: "",
    hdcqDet: "",
    settleAmt: 0,
    payment: 0,
    tdsamt: 0,
    invRaised: false,
    othDisc: 0,
    disAllAmt: 0,
    disAllDet: "",
    reclAmt: 0,
    hBillAmt:0,
    pPaidAmt:0,
    rTDSAMT:0,
    reclPayment:0,
    rReclBal:0,
    reclRecAmt: 0,
    reclNeftNo: "",
    billRaised: false,
    rBilRaised: false,
    rInvRaised: false,
    isDspInCon: false,
    treatingDrName:"",
    ailmentDiagnosis:"",
    deliveryamt:0,
    scrutiny_By:0,
    imp_Iol_InjAmt:0,
    weightInKg:0,
    invoiceTypeId:53

  };
  const handleClose = useCallback(() => {
    
    setIsAddCorportate(false);
  }, []);

  
  return (
    <React.Fragment>
    <Create title="Add Realize Order" {...props} >
      <SimpleForm
        initialValues={defaultValues}
        redirect="edit"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined">
        <TextInput disabled={true}  source="billNo" label="Record No" fullWidth={true} formClassName={classes.one_4_input} />
            <TextInput validate={[required(), minLength(3)]} source="hBillNo" label="Hospital Bill No" fullWidth={true} formClassName={classes.two_4_input} />

            <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.three_4_input} />
            <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.last_4_input} />


            <TextInput source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.one_4_input} />
            <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} validate={[required()]} 
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true} formClassName={classes.two_4_input} onChange={handleOnChange}
              filter={{ isActive: 1 }}
              >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <DateInput validate={[required()]} label="DOA" source="doa" fullWidth={true} formClassName={classes.three_4_input} />
            <DateInput validate={[required()]} label="DOD" source="dod" fullWidth={true} formClassName={classes.last_4_input} />

            <TextInput validate={[required(), minLength(3)]} label="Card No" source="cardNo" fullWidth={true} formClassName={classes.one_three_input} />
            <TextInput validate={[required(), minLength(3)]} label="CCN No" source="ccnno" fullWidth={true} formClassName={classes.two_three_input} />

            <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              validate={[required()]} label="TPA Name" source="tpaId" reference="tpas" fullWidth={true} formClassName={classes.last_three_input}
              filter={{ isActive: 1 }}
              >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>

            <TextInput validate={[required(), minLength(3)]} source="policyNo" fullWidth={true} formClassName={classes.one_three_input} />
            <DateInput source="policyFrom" fullWidth={true} formClassName={classes.two_three_input} />
            <DateInput source="policyTo" fullWidth={true} formClassName={classes.last_three_input} />
            

            <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              validate={[required()]} label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.first_inline_input}
              filter={{ isActive: 1 }}
              >
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            {/* <ReferenceInput
              perPage={25} sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ 'name~like': searchText })}
              label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
              <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput> */}
            <FormDataConsumer formClassName={classes.last_inline_input}>
              {formDataProps => (
                <div style={{ display: "flex", flex: "rows" }}>
                  <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}
                    filter={{ isActive: 1 }}
                    >
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                  </ReferenceInput>
                  <Button onClick={() => { setIsAddCorportate(true) }}   ><AddIcon /></Button>
                </div>
              )}
            </FormDataConsumer>

            <DateInput validate={[required()]} label="Received At HDC" source="frecDate" fullWidth={true} formClassName={classes.one_4_input} />
            <DateInput label="Soft File Sub. Date" source="fVSubDate" fullWidth={true} formClassName={classes.two_4_input} />
            <DateInput label="Phy File Sub Date" source="fSubDate" fullWidth={true} formClassName={classes.three_4_input} />

            <ReferenceInput
              perPage={500} sort={{ field: 'name', order: 'ASC' }}
              label="Status" source="statusId" reference="lookups" formClassName={classes.last_4_input}
              filter={{ type: 'RO' }} fullWidth={true} >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderPatmentFields {...formDataProps}  isImp_IOL_Inj={isImp_IOL_Inj}/>
              )}
            </FormDataConsumer>

            <FormDataConsumer>
              {formDataProps => (
                <RealizationOrderOtherFields {...formDataProps} />
              )}
            </FormDataConsumer>
      </SimpleForm>
    </Create>
     <Drawer
     anchor="right"
     onClose={handleClose}
     classes={{
       paper: myclasses.drawerPaper,
     }}
     open={isAddCorportate} >
    {isAddCorportate && <CorporateCreate onClose={handleClose} />}
     
   </Drawer>
   </React.Fragment>
 
  );
};
